<template>
    <div id="jot-form-script">
        
    </div>
</template>

<script>
    export default {
        name: 'HubSpotForm',
        props: ['region', 'formid', 'portalid'],
        data () {
            return {
                jotFormInitialized: false
            }
        },
        mounted(){
            let self = this;
            $('#footerQuestion').on('shown.bs.modal', function(e) {
                if(!this.jotFormInitialized) {
                    setTimeout(() => {
                        self.getJotFormJavascript();
                    }, 1);
                }
            });
        },
        methods: {
            getJotFormJavascript: async function() {
                let self = this
                let jotFormScriptElement = document.getElementById('jot-form-script')
                let jotFormScript1 = document.createElement('script')
                jotFormScript1.setAttribute('src', 'https://js.hsforms.net/forms/embed/v2.js')
                jotFormScript1.setAttribute('type', 'text/javascript')
                jotFormScript1.setAttribute('charset', 'utf-8')
                jotFormScript1.onload = () => {
                    self.initiateJotForm(jotFormScriptElement)
                }

                jotFormScriptElement.appendChild(jotFormScript1)

                this.jotFormInitialized = true
            },
            initiateJotForm: async function(jotFormScriptElement) {
                let jotFormScript2 = document.createElement('script')
                    
                jotFormScript2.innerHTML = `
                    hbspt.forms.create({
                        region: "${this.region}",
                        portalId: "${this.portalid}",
                        formId: "${this.formid}",
                    });
                `;
                    
                jotFormScriptElement.appendChild(jotFormScript2)
            }
        }
    }
</script>