<template>
    <el-form ref="controller" label-position="top" :model="controller" :rules="rules">
        <div>
            <p>
                {{$t('with_a_controller_you_can_control_the_strips')}}
            </p>
        </div>
        <div class="input-group" v-show="!controller.product.id">
            <div class="row">
                <div class="col-lg-12">
                    <el-form-item :label="$t('controller_choice')" prop="controller_product" required v-if="!controller.product.id" v-tooltip.top-start="tooltips.controller_choice">
                        <el-button type="primary" plain @click="chooseController = true">{{$t('choose_controller')}}</el-button>
                    </el-form-item>
                </div>
            </div>
        </div>

        <div class="input-group selected-product" v-show="controller.product.id">
            <div class="row">
                <div class="col-lg-12 col-md-12">
                    <div class="row">
                        <div class="col-xs-5">
                            <img class="selected-product-image"
                                 :src="controller.product.image ? controller.product.image : '/images/afbeelding-niet-gevonden.jpg'" />
                        </div>
                        <div class="col-xs-7">
                            <div class="block-info">
                                <ul>
                                    <li>{{controller.product.product_name}}</li>
                                    <li class="link"><a target="_blank" :href="'/winkel/'+controller.product.url">{{$t('more_info')}}</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row" v-if="controller.product.id">
            <div class="col-sm-4 col-xs-12">
                <el-form-item class="input-group product-amount" >
                    <el-input-number v-model="controller.amount" :min="1" @change="updateAmount"></el-input-number>
                </el-form-item>
            </div>
            <div class="col-xs-12 col-sm-4">
                <el-button type="info" plain class="pull-right col-xs-12 xs-margintop-15"
                           @click="chooseController = true">
                    <span>{{$t('change')}}</span>
                </el-button>
            </div>
            <div class="col-xs-12 col-sm-4">
                <el-button class="col-xs-12" type="danger" @click="resetController">
                    {{$t('delete')}}
                </el-button>
            </div>
        </div>

        <div class="input-group" v-show="!controller.product.id">
            <div class="row">
                <div class="col-lg-12 col-md-12">
                    <div class="row">
                        <div class="col-md-12">
                            <el-alert :title="controller_alert_text ? controller_alert_text : $t('no_controller_chosen')"
                                      :type="controller_alert_text ? 'error' : 'warning'">
                            </el-alert>
                            <p></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <br/>
        <br/>

        <p v-if="used_rolls.length > 0">
            {{$t('we_already_classified_lightsources')}}
        </p>

        <el-alert v-if="used_rolls.length > 0"
                  :title="remainingRolls() ? table_alert_text : $t('chosen_controllers_are_sufficient')"
                  :type="remainingRolls() ? 'error' : 'success'">
        </el-alert>

        <power-progress-table
                :tableData="tableData"
                :strip_rolls="strip_rolls"
                :title1="$t('controllers_and_amplifiers')"
                :title2="$t('lightsources')"
                :title3="$t('not_yet_classified_lightsources')"
        ></power-progress-table>

        <div class="input-group">
            <el-dialog :visible.sync="chooseController" width="80%" :lock-scroll="true"
                       custom-class="lightsource-dialog"
                        :fullscreen="checkFullscreen ? true : false">
                <controller></controller>
                <span slot="footer" class="dialog-footer">
                    <hr class="dialog-footer-separator" />
                    <el-button type="info" plain @click="chooseController = false" class="white-background">{{$t('close')}}</el-button>
                    <el-button type="primary" plain @click="chooseController = false" class="white-background">{{$t('choose_this_controller')}}</el-button>
                </span>
            </el-dialog>
        </div>
    </el-form>
</template>

<script>
    import Controller from '../Popups/Controller.vue'
    import PowerProgressTable from '../../../PowerProgressTable.vue'
    import {mapState} from 'vuex'
    import _ from 'lodash'

    export default {
        name: 'ControllerTab',
        components:{Controller,PowerProgressTable},
        props: ["tooltips", 'controller_alert_text', 'tableData',  'strip_rolls', 'used_rolls'],
        data () {
            return {
                chooseController: false,
                rules: {
                    controller_product:  [
                        {required: true, message: this.$t('please_select_a_ledstrip'), trigger: 'blur'}
                    ]
                }
            }
        },
        computed: {
            ...mapState('language', {
                languageCode: state => state.language_code
            }),
            ...mapState('configurator', {
                light_colour: state => state.mainValues.light_colour
            }),
            ...mapState('controller', {
                controller: state => state.controller
            }),
            table_alert_text () {
                if(this.light_colour === 'single_colour')
                    return this.$t('we_recommened_adding_another_controller');
                else
                    return this.$t('we_recommened_adding_another_amplifier');
            },
            checkFullscreen(){
                return window.outerWidth <= 767;
            }
        },
        methods: {
            remainingRolls(){
                return (this.strip_rolls.length-this.used_rolls.length) > 0
            },
            updateAmount(value){
                this.$store.commit('controller/setProductAmount', value);
            },
            resetController () {
                this.$store.commit('controller/resetProduct');
            }
        }
    }
</script>
