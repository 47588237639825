<template>
    <div>
        <el-collapse accordion v-model="lightsource_pane" class="lightsources-collapse" @change="changeLightSourcePane">
            <el-collapse-item v-for="(lightsource, index) in lightsources" :name="index" :key="index">
                <template slot="title">
                    <h4 class="panel-title">
                        <span class="step-name">{{$t('lightsource')}} {{index+1}} {{lightsource.reference ? '(' + lightsource.reference + ')' : ''}}</span>
                        <span class="actions">
                            <span class="icons" @click.stop="duplicateLightsource(lightsource)">
                                <i :title="$t('duplicate')" class="fa fa-copy"></i>
                            </span>
                            <span class="icons" @click.stop="deleteLightsource(index)">
                                <i :title="$t('remove')" class="fa fa-trash"></i>
                            </span>
                        </span>
                    </h4>
                </template>
                <div class="row">
                    <div class="col-lg-12">
                        <el-tabs v-model="lightsource.activeName" :stretch="true" :before-leave="toTop" class="white-progress-tabs">
                            <el-tab-pane :label="$t('led_strip_choice')" name="led_strip">
                                <led-strip-tab :tooltips="tooltips" :lightsource_index="index"></led-strip-tab>
                            </el-tab-pane>

                            <el-tab-pane :label="$t('controller_choice')" name="led_profile" :disabled="!lightsource.led_strip.product.id">
                                <led-profile-tab :tooltips="tooltips" :lightsource_index="index"></led-profile-tab>
                            </el-tab-pane>

                            <el-tab-pane :label="$t('led_accessories_choice')" name="led_accessory" :disabled="!lightsource.led_strip.product.id">
                                <led-accessory-tab :tooltips="tooltips" :lightsource_index="index"></led-accessory-tab>
                            </el-tab-pane>
                        </el-tabs>
                    </div>
                </div>
            </el-collapse-item>
            <el-collapse-item class="add-lightsource" :name="lightsources.length" :key="lightsources.length"  >
                <template slot="title" >
                    <h4 class="panel-title">
                        <span class="step-name">{{$t('add_lightsource')}}</span>
                        <span class="actions">
                            <span class="icons">
                                <i :title="$t('add')" class="fa fa-plus"></i>
                            </span>
                        </span>
                    </h4>
                </template>
            </el-collapse-item>
        </el-collapse>
    </div>
</template>

<script>
    import LedStripTab from './Tabs/LedStripTab.vue'
    import LedProfileTab from './Tabs/LedProfileTab.vue'
    import LedAccessoryTab from './Tabs/LedAccessoryTab.vue'
    import {mapState} from 'vuex'

    export default {
        name: 'Lightsources',
        props: ["tooltips"],
        components:{LedStripTab, LedProfileTab, LedAccessoryTab},
        data () {
            return {
            }
        },
        computed: {
            ...mapState('lightsources', {
                lightsources: state => state.lightsources
            }),
            lightsource_pane: {
                get: function(){
                    return this.$store.state.configurator.lightsource_pane
                },
                set: function (value) {
                    this.$store.commit('configurator/setLightsourcePane', value)
                }
            }
        },
        methods: {
            addItem (type) {
                if (type === 'lightsource') {
                    var lightsource = {
                        activeName: 'led_strip',
                        reference: null,
                        length: null,
                        powers:[],
                        colour_choices: [],
                        led_strip: {
                            product: {},
                            amount: 0,
                            valid: false
                        },
                        led_profile: {
                            product: {},
                            amount: 0,
                            lens: ''
                        },
                        accessories: []
                    }
                    this.lightsources.push(lightsource)
                }
            },
            duplicateLightsource(lightsource){
                lightsource = JSON.parse(JSON.stringify(lightsource))
                this.lightsources.push(lightsource)
            },
            deleteLightsource(index){
                let self = this
                this.$confirm(this.$t('are_you_sure'), {
                    title: this.$t('warning'),
                    fullscreen: true,
                    confirmButtonText: 'Ja',
                    cancelButtonText: 'Nee',
                    type: 'warning'
                }).then(() => {
                    self.lightsources.splice(index, 1)
                });
            },
            changeLightSourcePane (index) {
                if(index >= this.lightsources.length) {
                    this.addItem('lightsource')
                }

                this.lightsource_pane = index;
            },
            toTop(newName, oldName){
                if($(this.$el).offset())
                    $('html, body').animate({
                        scrollTop: $(this.$el).offset().top-162
                    }, 0);
            }
        }
    }
</script>
