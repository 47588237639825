import Vue from 'vue'

const state = {
    itemCount: 0,
    isLoggedIn: false
}

const getters = {}

const actions = {
    async getItems({commit, state}){
        let response = await Vue.http.get("/offertelijst/get");
        if (!response) return false;
        let count = 0;
        for (const item in response.data.estimate.items) {
            count += response.data.estimate.items[item].qty;
        }
        commit('setItemCount', count);
        commit('setLoggedIn', response.data.isLoggedIn);
    }
}

const mutations = {
    setItemCount(state, count){
        Vue.set(state, 'itemCount', count);
    },
    setLoggedIn(state, isLoggedIn){
        Vue.set(state, 'isLoggedIn', isLoggedIn);
    }
}

export default {
    namespaced:true,
    state,
    getters,
    actions,
    mutations
}
