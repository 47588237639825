<template>
    <el-form label-position="top" v-if="lightsource.led_profile">
        <div v-if="lightsource.led_profile.product.id">
            <div class="row accessory-recommendations">
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <p>
                        {{$t('we_recommend_following_accessories')}}:
                    </p>
                    <div v-for="(accessory, index) in available_accessories" :key="index">
                        <p v-if="accessory.type === 'endcap'">{{$t('minimal')}} 1 {{accessory.product_name}}.</p>
                        <p v-else-if="accessory.type === 'endcap_wire'">{{$t('minimal')}} 1 {{accessory.product_name}}.</p>
                        <p v-else-if="accessory.type === 'bracket'">{{$t('minimal')}} 2 {{$t('bracket')}} + 1 {{$t('bracket_per_meter')}}. ({{estimateBrackets}} {{$t('bracket')}})</p>
                    </div>
                </div>
            </div>
            <div class="input-group product-amount">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="input-number">
                            <el-form-item label="Kies één of meerdere accessoires" v-if="available_accessories.length > 0" class="row-3" v-tooltip.top-start="tooltips.accessories">
                                <div class="eq-wh" v-for="(accessory, index) in available_accessories">
                                    <div class="content">
                                        <img class="image" :src="accessory.image">
                                        <div class="block-info">
                                            <h5>{{accessory.product_name}}</h5>
                                        </div>
                                        <el-input-number class="accessory-amount" :value="0" v-model="accessory.amount" :min="0" @change="updateAccessories(index)"></el-input-number>
                                    </div>
                                </div>
                            </el-form-item>
                            <div v-else>
                                {{$t('no_products_found')}}..
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <el-alert :title="$t('no_accessories_because_no_profile')"
                      type="warning">
            </el-alert>
        </div>

        <hr class="footer-separator" />

        <div class="row">
            <div class="col-xs-12 col-sm-6">
                <el-button type="info" plain class="white-background col-xs-12"
                           @click="nextTabPane('led_profile')">
                    <span><i class="fa fa-chevron-left"></i> {{$t('led_profile')}}</span>
                </el-button>
            </div>
            <div class="col-xs-12 col-sm-6">
                <el-button type="primary" class="xs-margintop-15 pull-right col-xs-12"
                           @click="nextCollapsePane(3)">
                    <span>{{$t('management')}} <i class="fa fa-chevron-right"></i></span>
                </el-button>
            </div>
        </div>
    </el-form>
</template>

<script>
    import Vue from 'vue'
    import {mapState} from 'vuex'
    import _ from 'lodash'

    export default {
        name: 'LedAccessoryTab',
        props: ["tooltips", "lightsource_index"],
        components:{},
        data () {
            return {
                chooseProfileAccessories: false,
                available_accessories: [],
                lightsource: {}
            }
        },
        computed: {
            ...mapState('lightsources', {
                lightsources: state => state.lightsources
            }),
            estimateBrackets(){
                let total_brackets = 2;
                let extra_length = Math.round(parseFloat(this.lightsource.length)-1);
                if(extra_length >= 1){
                    total_brackets += extra_length
                }
                return total_brackets;
            }
        },
        watch: {
            'lightsource.led_profile.product':  function (newValue, oldValue) {
                if(newValue !== oldValue) {
                    this.getAccessories()
                    this.lightsource.accessories = []
                }
            }
        },
        mounted () {
            this.lightsource = this.lightsources[this.lightsource_index]
            this.getAccessories()
        },
        methods: {
            nextTabPane(value) {
                this.lightsource.activeName = value;
            },
            getAccessories() {
                let self = this

                Vue.http.post(
                    '/led/get/accessories',
                    {
                        profile: self.lightsource.led_profile.product ? self.lightsource.led_profile.product : false
                    },
                    {
                        responseType: 'json'
                    }
                ).then(function(response){
                    if (response.data){
                        self.available_accessories = _.map(response.data, function(accessory){
                            if(accessory.type === 'bracket')
                                accessory.amount = self.estimateBrackets
                            else if(accessory.type === 'endcap' || accessory.type === 'endcap_wire')
                                accessory.amount = 1

                            return accessory
                        });

                        _.forEach(self.available_accessories, function(accessory, index){
                            self.updateAccessories(index)
                        })
                    }
                });
            },
            updateAccessories(accessory_id){
                let found_index = _.findIndex(this.lightsource.accessories, {product_id: this.available_accessories[accessory_id].product_id})

                if(found_index > -1){
                    if(this.available_accessories[accessory_id].amount === 0)
                        this.lightsource.accessories.splice(found_index, 1);
                    else
                        this.lightsource.accessories[found_index].amount = this.available_accessories[accessory_id].amount
                } else if(this.available_accessories[accessory_id].amount !== 0)
                    this.lightsource.accessories.push({
                        "product_id": this.available_accessories[accessory_id].product_id,
                        "product_name": this.available_accessories[accessory_id].product_name,
                        "amount": this.available_accessories[accessory_id].amount,
                        "image": this.available_accessories[accessory_id].image
                    })
            },
            nextCollapsePane(value) {
                this.$store.commit('configurator/setPane', value)
                if(value === 3) {
                    setTimeout(function () {
                        $('html, body').animate({
                            scrollTop: $("#controller-parent").offset().top - 83
                        }, 500);
                    }, 301)
                }
            }
        }
    }
</script>
