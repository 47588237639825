import Vue from 'vue'
import Vuex from 'vuex'
import VueResource from 'vue-resource'

import lightsources from './modules/lightsources'
import configurator from './modules/configurator'
import controller from './modules/controller'
import supplies from './modules/supplies'
import language from './modules/language'
import header_cart from './modules/header_cart'

import createLogger from './plugins/logger'
import createDataLayer from './plugins/datalayer'

Vue.use(Vuex)
Vue.use(VueResource);

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
    modules: {
        lightsources,
        configurator,
        controller,
        supplies,
        language,
        header_cart
    },
    plugins: debug ? [createLogger(), createDataLayer()] : [createDataLayer()]
})