<template>
  <section id="shopping-cart" class="background">
    <div class="container">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12">
          <a href="/offertelijst">
            <i class="fas fa-long-arrow-left"></i>
            {{ $t("cart") }}
          </a>
          <h3>
            {{ $t("checkout") }}
          </h3>
        </div>
      </div>
      
      <div class="row">
        <div class="col-xs-12 col-md-9">
          <!-- Reference input field -->
          <div class="row overlay-background-mobile">
            <div class="col-xs-12 col-sm-12 col-md-12" id="reference-item">
              <div class="bg-wrapper width-100 overlay-background">
                <h3 class="bold">
                  {{ $t("reference") }}
                </h3>
                {{ $t("fill_in_reference") }}
                <div class="width-100 md-form">
                  <input
                    id="reference-number"
                    name=""
                    type="text"
                    class="form-control"
                    aria-required="true"
                    v-model="referenceNumber"
                  />
                  <label for="reference-number" class="input-label">
                    {{ $t("reference") }}
                  </label>
                </div>
              </div>
            </div>
          </div>
          
          <AddressesSlider
            :activeAddress.sync="activeDeliveryAddress"
            :addresses="deliveryAddresses"
            @openAddressesModal="addressesModalVisible = true"
          ></AddressesSlider>
        </div>
        <div class="col-xs-12 col-md-3">
          <div
            v-if="isLoggedIn"
            class="row overlay-background-mobile no-padding-bottom"
          >
            <div class="col-xs-12">
              <div class="bg-wrapper side-bar width-100 overlay-background">
                <div class="row">
                  <div class="col-xs-6">
                    <div class="font-12">
                      {{ $t("order_amount") }}
                    </div>
                  </div>
                  <div class="col-xs-6">
                    <div class="font-12 text-right">
                      {{ formatPrice(estimate.grand_total) }}
                    </div>
                  </div>
                </div>              
                <div class="row">
                  <div class="col-xs-6">
                    <div class="font-12">
                      {{ $t("sub_total") }}
                    </div>
                  </div>
                  <div class="col-xs-6">
                    <div class="font-12 text-right" v-if="estimate.soc">
                      {{formatPrice(estimate.grand_total + 15)}}
                    </div>
                    <div class="font-12 text-right" v-else>
                      {{formatPrice(estimate.grand_total)}}
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xs-6">
                    <div class="font-12">
                      {{ $t("tax_amount") }}
                    </div>
                  </div>
                  <div class="col-xs-6">
                    <div class="font-12 text-right" v-if="estimate.soc">
                      {{formatPrice((estimate.grand_total + 15) * 0.21)}}
                    </div>
                    <div class="font-12 text-right" v-else>
                      {{ formatPrice(estimate.grand_total * 0.21)}}
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="bg-wrapper width-100 no-vertical-margin dark-background"
              >
                <div class="row">
                  <div class="col-xs-7 col-sm-7">
                    <div class="list-item font-12 text-left white-text">
                      {{ $t("order_amount_inc_btw") }}
                    </div>
                  </div>
                  <div class="col-xs-5 col-sm-5">
                    <div class="list-item font-12 text-right white-text" v-if="estimate.soc">
                      {{ formatPrice((estimate.grand_total+15) * 1.21)}}
                    </div>
                    <div class="list-item font-12 text-right white-text" v-else>
                      {{ formatPrice(estimate.grand_total * 1.21)}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-9">
          <div class="row overlay-background-mobile">
            <div class="col-xs-12 col-sm-12 col-md-12">
              <div
                class="bg-wrapper width-100 overlay-background"
                id="complete-transaction"
              >
                <div class="row">
                  <div class="col-xs-12 col-sm-12 col-md-8">
                    {{ $t("if_you_order_you_agree_w_conditions") }}
                    <a
                      href="https://www.nedelkodatasheets.nl/Datasheets/Leveringsvoorwaarden/Metaalunievoorwaarden_2019.pdf"
                    >
                      {{ $t("terms_and_conditions") }}
                    </a>
                  </div>
                  <div class="col-xs-12 col-sm-12 col-md-4">
                    <button
                      type="button"
                      class="button-green-slide width-100"
                      @click="placeOrder()"
                      v-loading="loading"	
                    >
                      {{ $t("place_order") }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <AddressesModal
      :visible.sync="addressesModalVisible"
      :fullscreen="checkFullscreen"
      :addresses="deliveryAddresses"
      :activeAddress.sync="activeDeliveryAddress"
      @openNewAddressModal="newAddressModalVisible = true"
    ></AddressesModal>
    <NewAddressModal
      :visible="newAddressModalVisible"
      :fullScreen="checkFullscreen"
      @closeDialog="closeNewAddressDialog"
    ></NewAddressModal>
  </section>
</template>

<script>
import Vue from "vue";
import AddressesSlider from "./partials/AddressesSlider.vue";
import AddressesModal from "./partials/AddressesModal.vue";
import NewAddressModal from "./partials/NewAddressModal.vue";
export default {
  name: "CheckoutDetails",
  components: { AddressesSlider, AddressesModal, NewAddressModal },
  props: [],
  data() {
    return {
      estimate: {},
      deliveryAddresses: [],
      isLoggedIn: false,
      referenceNumber: "",
      activeDeliveryAddress: null,
      addressesModalVisible: false,
      newAddressModalVisible: false,
      loading: false
    };
  },
  computed: {
    checkFullscreen() {
      return window.screen.width <= 992;
    },
  },
  mounted() {
    this.getItems();
    this.getClientAddresses();
  },
  methods: {
    async closeNewAddressDialog() {
      this.newAddressModalVisible = false;
      await this.getClientAddresses();
    },
    formatPrice(price) {
      if (this.isLoggedIn !== false || price !== "Op aanvraag") {
        return Intl.NumberFormat("nl-NL", {
          style: "currency",
          currency: "EUR",
        }).format(price);
      }
    },
    async getItems() {
      let response = await Vue.http.get("/offertelijst/get");
      if (!response) return false;
      this.estimate = response.body.estimate;
      this.isLoggedIn = response.body.isLoggedIn;
    },
    async getClientAddresses() {
      let response = await Vue.http.get("/estimate/getClientAddresses");
      if (!response.data.count > 0) this.deliveryAddresses = false;
      this.deliveryAddresses = response.data.addresses;
    },
    async placeOrder() {
      if (this.activeDeliveryAddress == null) {
        alert(this.$t("choose_a_delivery_address"));
        return false;
      }

      this.loading = true;
      let response = await Vue.http.post("/offertelijst/placeOrder", {
        addressCode: this.activeDeliveryAddress,
        reference: this.referenceNumber,
      });
      this.loading = false;
      if (response != false) window.location.href = "offertelijst/thanks";
      else console.log(response);
    },
  },
};
</script>

<style>
#shopping-cart .side-bar {
  margin-bottom: 0 !important;
}
#shopping-cart .no-vertical-margin {
  margin-top: -10px !important;
}
</style>
