import Vue from "vue";

import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from "body-scroll-lock";

import {
  Input,
  InputNumber,
  Select,
  Radio,
  RadioGroup,
  Option,
  OptionGroup,
  Form,
  FormItem,
  Alert,
  Dialog,
  Button,
  Collapse,
  CollapseItem,
  Tabs,
  TabPane,
  Table,
  TableColumn,
  Loading,
  Checkbox
} from "element-ui";

const isIE = function() {
  return !Vue.prototype.$isServer && !isNaN(Number(document.documentMode));
};

const isEdge = function() {
  return !Vue.prototype.$isServer && navigator.userAgent.indexOf("Edge") > -1;
};

import lang from "element-ui/lib/locale/lang/nl";
import locale from "element-ui/lib/locale";

// configure language
locale.use(lang);

Vue.prototype.$ELEMENT = { size: "small" };

// Fixes an issue with filters not working on mobile
Select.computed.readonly = function() {
  if (this.id !== "mobile-search" && screen.width <= 768) {
    return true;
  } else if (this.id === "mobile-search") {
    return false;
  } else {
    return (
      !this.filterable ||
      this.multiple ||
      (!isIE() && !isEdge() && !this.visible)
    );
  }
};

Dialog.props.modalAppendToBody.default = true;
Dialog.props.appendToBody.default = true;
Dialog.props.appendToBody.default = true;

Dialog.watch.visible = function(val) {
  if (val) {
    this.closed = false;
    this.$emit("open");
    this.$el.addEventListener("scroll", this.updatePopper);
    this.$nextTick(() => {
      this.$refs.dialog.scrollTop = 0;
    });
    if (this.appendToBody) {
      document.body.appendChild(this.$el);
    }
    disableBodyScroll(this.$el);
  } else {
    enableBodyScroll(this.$el);
    this.$el.removeEventListener("scroll", this.updatePopper);
    if (!this.closed) this.$emit("close");
  }
};

Dialog.beforeDestroy = function() {
  clearAllBodyScrollLocks();
};

Vue.use(Input);
Vue.use(InputNumber);
Vue.use(Select);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Option);
Vue.use(OptionGroup);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Alert);
Vue.use(Dialog);
Vue.use(Button);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(Tabs);
Vue.use(TabPane);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Loading);
Vue.use(Checkbox);

import "./element-variables.scss";
import "./style.scss";
