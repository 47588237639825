<template>
    <div>
        <div class="row">
            <div class="col-lg-12">
                <p v-if="!remainingRolls()">{{$t('we_dont_recommend_extra_amplifiers')}}</p>
                <p v-if="remainingRolls()">{{$t('we_recommend_extra_amplifiers')}}</p>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <h5 class="subtitle" v-tooltip.top-start="tooltips.amplifier">{{$t('amplifier_choice')}}:</h5>
            </div>
        </div>

        <el-form label-position="top">
            <div class="input-group">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="input-number">
                            <el-form-item v-if="available_amplifiers.length > 0" class="row-3">
                                <div class="eq-wh" :key="index" v-for="(amplifier, index) in available_amplifiers">
                                    <div class="content">
                                        <div class="bg-image"
                                             :style="amplifier.image ? 'background-image: url(' + amplifier.image + ')' : 'background-image: url(/images/afbeelding-niet-gevonden.jpg)'"
                                        ></div>
                                        <div class="block-info">
                                            <h5>{{amplifier.description}}</h5>
                                        </div>
                                        <el-input-number class="product-amount" :value="0" v-model="amplifier.amount" :min="0" @change="updateAmplifiers(index)"></el-input-number>
                                    </div>
                                </div>
                            </el-form-item>
                            <div v-else>
                                {{$t('no_products_found')}}..
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </el-form>

        <p v-if="used_rolls.length > 0">
            {{$t('we_already_classified_lightsources')}}
        </p>

        <el-alert v-if="used_rolls.length > 0"
                  :title="remainingRolls() ? table_alert_text : $t('chosen_controllers_are_sufficient')"
                  :type="remainingRolls() ? 'error' : 'success'">
        </el-alert>

        <power-progress-table
                :tableData="tableData"
                :strip_rolls="strip_rolls"
                :title1="$t('controllers_and_amplifiers')"
                :title2="$t('lightsources')"
                :title3="$t('not_yet_classified_lightsources')"
        ></power-progress-table>
    </div>
</template>

<script>
    import _ from 'lodash'
    import PowerProgressTable from '../../../PowerProgressTable.vue'
    import Vue from 'vue'
    import {mapState} from 'vuex'

    export default {
        name: 'AmplifierTab',
        components:{PowerProgressTable},
        props: ["tooltips", 'tableData', 'strip_rolls', 'used_rolls'],
        data () {
            return {
                available_amplifiers: []
            }
        },
        computed: {
            ...mapState('language', {
                languageCode: state => state.language_code
            }),
            ...mapState('configurator', {
                mainValues: state => state.mainValues,
                light_colour: state => state.mainValues.light_colour
            }),
            ...mapState('controller', {
                controller: state => state.controller,
                amplifiers: state => state.amplifiers
            }),
            table_alert_text () {
                if(this.light_colour === 'single_colour')
                    return this.$t('we_recommened_adding_another_controller');
                else
                    return this.$t('we_recommened_adding_another_amplifier');
            }
        },
        mounted(){
            this.getAmplifiers()
        },
        methods: {
            remainingRolls(){
                return (this.strip_rolls.length-this.used_rolls.length) > 0
            },
            nextTabPane(value) {
                this.controller.activeName = value;
            },
            getAmplifiers(){
                var self = this
                Vue.http.post(
                    '/led/get/amplifiers',
                    {
                        colour: self.mainValues.light_colour,
                        voltage: self.mainValues.voltage
                    },
                    {
                        responseType: 'json'
                    }
                ).then(function (response) {
                    if (response.data) {
                        self.available_amplifiers = _.map(response.data, function(amplifier){
                            amplifier.amount = 0;
                            return amplifier
                        });
                    }
                });
            },
            updateAmplifiers(amplifier_id){
                let found_index = _.findIndex(this.amplifiers, {product_id: this.available_amplifiers[amplifier_id].product_id})

                if(found_index > -1){
                    if(this.available_amplifiers[amplifier_id].amount === 0)
                        this.$store.commit('controller/removeAmplifier', found_index);
                    else
                        this.$store.commit('controller/updateAmplifierAmount', {
                            index: found_index,
                            amount: this.available_amplifiers[amplifier_id].amount
                        });

                } else if(this.available_amplifiers[amplifier_id].amount !== 0)
                    this.$store.commit('controller/addAmplifier', {
                        "product_id": this.available_amplifiers[amplifier_id].product_id,
                        "product_name": this.available_amplifiers[amplifier_id].description,
                        "amount": this.available_amplifiers[amplifier_id].amount,
                        "max_current": this.available_amplifiers[amplifier_id].max_current,
                        "channels": this.available_amplifiers[amplifier_id].channels,
                        "image": this.available_amplifiers[amplifier_id].image
                    });
            }
        }
    }
</script>
