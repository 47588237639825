<template>
    <div>
        <el-form>
            <div class="row">
                <div class="col-md-3">
                    {{$t('filter_led_profiles')}} :
                </div>
                <div class="col-md-3">
                    <el-form-item :label="$t('mount')">
                        <el-select clearable @change="getProfiles" v-model="mount" :popper-append-to-body="false" :placeholder="$t('built_in')">
                            <el-option
                                    v-for="(mount, index) in mounts"
                                    :key="index"
                                    :label="mount"
                                    :value="index">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </div>
            </div>
            <hr class="filter-separator" />
        </el-form>
        <div class="row">
            <div class="input-group">
                <div class="col-lg-12">
                    <div class="radio-image row-4" v-if="products.length > 0">
                        <el-radio-group :value="lightsource.led_profile.product">
                            <el-radio :label="prod" v-for="prod in products" :key="prod.id" @change="setProduct(prod)">
                                <div class="bg-image"
                                     :style="prod.image ? 'background-image: url(' + prod.image + ')' : 'background-image: url(/images/afbeelding-niet-gevonden.jpg)'"
                                ></div>
                                <div class="block-info">
                                    <ul>
                                        <li>{{prod.product_name}}</li>
                                        <li>{{prod.mount}}</li>
                                        <li class="link"><a target="_blank" :href="'/winkel/'+prod.url">{{$t('more_info')}}</a></li>
                                    </ul>
                                </div>
                            </el-radio>
                        </el-radio-group>
                    </div>
                    <div v-else>
                        {{$t('no_products_found')}}..
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue'
    import _ from 'lodash'
    import {mapState} from 'vuex'

    export default {
        name: 'LedProfile',
        props: ['lightsource_index'],
        data () {
            return {
                products: [],
                mount: '',
                mounts: []
            }
        },
        watch: {
        },
        computed: {
            ...mapState('lightsources', {
                lightsources: state => state.lightsources
            }),
            ...mapState('configurator', {
                mainValues: state => state.mainValues
            })
        },
        mounted () {
            this.lightsource = this.lightsources[this.lightsource_index]
            this.getProfiles()
        },
        methods: {
            getAvailableProfileValues(){
                var self = this
                Vue.http.post(
                    '/led/getLightsourceProfileFilterValues',
                    {
                        mount: self.mount ? self.mount : false
                    },
                    {
                        responseType: 'json'
                    }
                ).then(function(response){
                    if (response.data){
                        self.mounts = response.data.mounts
                    }
                });
            },
            async getProfiles() {
                await this.getAvailableProfileValues();

                let self = this
                Vue.http.post(
                    '/led/get/profiles',
                    {
                        mount: self.mount ? self.mount : false,
                        light_colour: self.mainValues.light_colour ? self.mainValues.light_colour : false
                    },
                    {
                        responseType: 'json'
                    }
                ).then(function(response){
                    if (response.data){
                        self.products = response.data;

                        if (!_.find(self.products, {id: self.lightsource.led_profile.product.id})) {
                            self.$store.commit('lightsources/setLedprofile', {
                                index: self.lightsource_index,
                                product: ''
                            })
                        }
                    }
                });
            },
            setProduct(value){
                this.$store.commit('lightsources/setLedprofile', {
                    index: this.lightsource_index,
                    product: value
                })
            }
        }
    }
</script>
