import Vue from 'vue'

const state = {
    language_code: ''
}

const getters = {}

const actions = {
}

const mutations = {
    setLanguage(state, data){
        Object.assign(state, data)
    }
}

export default {
    namespaced:true,
    state,
    getters,
    actions,
    mutations
}
