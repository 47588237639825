<template>
    <el-form label-position="top" ref="led_profile" :model="lightsource" :rules="rules" v-if="lightsource.led_profile">
        <div class="row">
            <div class="col-lg-12">
                <p>
                    {{$t('led_profile_finish')}}
                </p>

                <el-alert
                        v-if="lightsource.led_strip.product && lightsource.led_strip.product.power > 14.4"
                        :title="$t('cooling_advice_profile')"
                        type="warning">
                </el-alert>
            </div>
        </div>
        <div class="input-group" v-if="!lightsource.led_profile.product.id">
            <div class="row">
                <div class="col-lg-12">
                    <el-form-item :label="$t('led_profile_choice')" v-tooltip.top-start="tooltips.led_choice">
                        <el-button type="primary" plain @click="chooseLedprofile = true" class="white-background">{{$t('led_profile_choice')}}</el-button>
                        <el-button v-if="lightsource.led_profile.product.id" type="danger" @click="resetLedProfile">{{$t('delete_chosen_profile')}}</el-button>
                    </el-form-item>
                </div>
            </div>
        </div>

        <div class="input-group" v-if="lightsource.led_profile.product.id && lightsource.length">
            <div class="row">
                <div class="col-sm-12">
                    <el-form-item :label="$t('for_this_configuration_we_advice') + ' ' + Math.ceil(lightsource.length / 2) + ' LED ' + (Math.ceil(lightsource.length / 2) > 1 ? $t('profiles') : $t('profile')) + ' ' + $t('of_two_meters')"></el-form-item>
                </div>
            </div>
        </div>

        <div class="selected-product input-group" v-if="lightsource.led_profile.product.id">
            <div class="row">
                <div class="col-lg-12 col-md-12">
                    <div class="row">
                        <div class="col-sm-5">
                            <img class="selected-product-image"
                                 :src="lightsource.led_profile.product.image ? lightsource.led_profile.product.image : '/images/afbeelding-niet-gevonden.jpg'" />
                        </div>
                        <div class="col-sm-7">
                            <div class="block-info">
                                <ul>
                                    <li>{{lightsource.led_profile.product.product_name}}</li>
                                    <li>{{lightsource.led_profile.product.mount}}</li>
                                    <li class="link"><a target="_blank" :href="'/winkel/'+lightsource.led_profile.product.url">{{$t('more_info')}}</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row" v-if="lightsource.led_profile.product.id">
            <div class="col-xs-12 col-sm-4">
                <el-form-item class="input-group product-amount">
                    <el-input-number v-model="lightsource.led_profile.amount" :min="1"></el-input-number>
                </el-form-item>
            </div>
            <div class="col-xs-12 col-sm-4">
                <el-button type="info" plain class="pull-right xs-margintop-15 col-xs-12"
                           @click="chooseLedprofile = true">
                    <span>{{$t('change_profile')}}</span>
                </el-button>
            </div>
            <div class="col-xs-12 col-sm-4">
                <el-button class="col-xs-12" v-if="lightsource.led_profile.product.id" type="danger" @click="resetLedProfile">
                    {{$t('remove_profile')}}
                </el-button>
            </div>
        </div>

        <div class="input-group" v-show="lightsource.led_profile.product.id">
            <div class="row">
                <div class="col-sm-6">
                    <el-form-item v-if="lightsource.led_profile.product.id" :label="$t('choose_lens')" prop="led_profile.lens" v-tooltip.top-start="tooltips.lens_choice" required="">
                        <el-select :popper-append-to-body="false" v-model="lightsource.led_profile.lens" value-key="id" :placeholder="$t('lens')">
                            <el-option
                                    v-for="lens in lenses"
                                    :key="lens.id"
                                    :label="lens.product_name"
                                    :value="lens">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </div>
            </div>
        </div>

        <div class="input-group">
            <el-dialog :visible.sync="chooseLedprofile" width="80%" :lock-scroll="true"
                       custom-class="lightsource-dialog"
                       :fullscreen="!!checkFullscreen">
                <led-profile :lightsource_index="lightsource_index"></led-profile>
                <span slot="footer" :class="'dialog-footer-profile'+lightsource_index">
                    <hr class="dialog-footer-separator" />
                    <el-button type="info" plain @click="chooseLedprofile = false" class="white-background">{{$t('close')}}</el-button>
                    <el-button type="primary" plain @click="chooseLedprofile = false" class="white-background">{{$t('choose_this_led_profile')}}</el-button>
                </span>
            </el-dialog>
        </div>

        <hr class="footer-separator" />

        <div class="row">
            <div class="col-xs-12 col-sm-6">
                <el-button type="info" plain class="next-step white-background col-xs-12"
                           @click="nextTabPane('led_strip')">
                    <span><i class="fa fa-chevron-left"></i> {{$t('led_strip')}}</span>
                </el-button>
            </div>
            <div class="col-xs-12 col-sm-6">
                <el-button type="primary" class="xs-margintop-15 pull-right col-xs-12"
                           @click="nextTabPane('led_accessory')">
                    <span>{{$t('led_accessories')}} <i class="fa fa-chevron-right"></i></span>
                </el-button>
            </div>
        </div>
    </el-form>
</template>

<script>
    import LedProfile from '../Popups/LedProfile.vue'
    import {mapState} from 'vuex'
    import Vue from 'vue'

    export default {
        name: 'LedProfileTab',
        props: ["tooltips", "lightsource_index"],
        components:{LedProfile},
        data () {
            return {
                rules: {
                    'led_profile.lens':  [
                        {required: true, message: this.$t('please_select_a_lens'), trigger: 'blur'}
                    ]
                },
                lenses: [],
                chooseLedprofile: false,
                lightsource: {}
            }
        },
        watch: {
            "lightsource.led_profile.product": function (newValue, oldValue) {
                if(newValue !== oldValue)
                    this.getLenses()
            }
        },
        computed: {
            ...mapState('language', {
                languageCode: state => state.language_code
            }),
            ...mapState('lightsources', {
                lightsources: state => state.lightsources
            }),
            checkFullscreen(){
                return window.outerWidth <= 767;
            }
        },
        mounted (){
            this.lightsource = this.lightsources[this.lightsource_index]
        },
        methods: {
            nextTabPane(value) {
                let self = this;

                this.$refs['led_profile'].validate((valid) => {
                    if (valid) {
                        self.lightsource.activeName = value;
                    } else {
                        return false;
                    }
                });
            },
            getLenses(){
                let self = this

                Vue.http.post(
                    '/led/get/lenses',
                    {
                        profile_id: self.lightsource.led_profile.product.id
                    },
                    {
                        responseType: 'json'
                    }
                ).then(function(response){
                    if (response.data){
                        self.lenses = response.data;
                    }
                });
            },
            resetLedProfile(){
                this.lightsource.led_profile.product = ''
            }
        }
    }
</script>
