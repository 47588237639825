import Vue from "vue";

const state = {
    supplies: [],
    totalWattage: []
}

const getters = {}

const actions = {
}

const mutations = {
    setTotalWattage(state, data){
        Vue.set(state, 'totalWattage', data);
    },
    setSupplies(state, data){
        Vue.set(state, 'supplies', data);
    }
}

export default {
    namespaced:true,
    state,
    getters,
    actions,
    mutations
}
