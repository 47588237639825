<template>
    <div v-if="tableData">

        <div v-if="hasWarning">
            <br />
            <el-alert
                      :title="$t('unrecommened_usage_above_80')"
                      :type="'warning'">
            </el-alert>
        </div>

        <el-table :data="tableData"
                  v-if="tableData.length>0"
                  :empty-text="$t('no_selected_lightsources')"
                  :span-method="objectSpanMethod"
                  class="power-table"
                  border style="width: 100%; margin-top: 20px">
            <el-table-column
                    :label="title1 ? title1 : ''">
                <el-table-column
                        prop="controller"
                        :label="$t('productname')">
                </el-table-column>
                <el-table-column
                        prop="max_wattage"
                        :label="light_colour === 'single_colour' ? 'Max. ' + $t('wattage_per_channel') : 'Max. ' + $t('wattage')"
                        width="120">
                    <template slot-scope="scope">
                        {{scope.row.max_wattage}}W
                        <span v-if="light_colour === 'rgbw'" v-tooltip.top-start="$t('rgbw_divided_power')"></span>
                    </template>
                </el-table-column>
            </el-table-column>
            <el-table-column :label="title2 ? title2 : ''">
                <el-table-column
                        prop="lightsources"
                        :label="$t('reference')">
                    <template slot-scope="scope">
                        <el-select v-model="scope.row.lightsources" @change="updateWattage($event, scope.row)"
                                   :popper-append-to-body="false"
                                   :no-data-text="$t('all_lightsources_classified')"
                                   multiple placeholder="Select" value-key="id">
                            <el-option-group
                                    v-for="(group, key) in notSelected(scope.row)"
                                    v-if="group.length>0"
                                    :key="key"
                                    :label="key">
                                <el-option
                                        v-for="item in group"
                                        :disabled="isPossible(scope.row, item)"
                                        :key="item.id"
                                        :label="item.label"
                                        :value="item">
                                </el-option>
                            </el-option-group>
                        </el-select>
                    </template>
                </el-table-column>
                <el-table-column :label="$t('wattage')" width="120"
                                 prop="used_wattage">
                    <template slot-scope="scope">
                        {{(scope.row.used_wattage).toFixed(0)}}W
                        <el-button class="pull-right" type="warning" size=mini round v-if="usedPercentages[scope.$index] > 80">{{parseInt(usedPercentages[scope.$index])}}%</el-button>
                        <el-button class="pull-right" type="success" size=mini round v-else>{{parseInt(usedPercentages[scope.$index])}}%</el-button>
                    </template>
                </el-table-column>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
    import _ from 'lodash'
    import {mapState} from 'vuex'

    export default {
        name: 'PowerProgressTable',
        props: ['tableData', 'strip_rolls', 'title1', 'title2', 'title3'],
        computed: {
            grouped_rolls () {
                return _.groupBy(this.strip_rolls, 'lightsource_label');
            },
            usedPercentages() {
                return _.map(this.tableData, (channel) => {
                    return (channel.used_wattage / channel.max_wattage * 100)
                })
            },
            hasWarning () {
                return _.max(this.usedPercentages) > 80
            },
            ...mapState('configurator', {
                light_colour: state => state.mainValues.light_colour
            })
        },
        methods: {
            objectSpanMethod({ row, column, rowIndex, columnIndex }) {
                if (columnIndex === 0) {
                    if (row.channel === 1) {
                        return {
                            rowspan: row.no_of_channels,
                            colspan: 1
                        }
                    } else {
                        return {
                            rowspan: 0,
                            colspan: 0
                        };
                    }
                }
            },
            notSelected (row) {
                let available = _.cloneDeep(this.grouped_rolls);

                _.each(this.tableData, function(channel) {
                    if (row.controller_id + '' + row.channel + '' + row.product_id !== channel.controller_id + '' + channel.channel + '' + channel.product_id) {
                        available = _.mapValues(available, (lightsource) => {
                            return _.pullAllBy(lightsource, channel.lightsources, 'id')
                        })
                    }
                });

                return available;
            },
            isPossible (row, item){
                let selected = _.findIndex(row.lightsources, {id: item.id}) > -1;
                return !selected && row.used_wattage+item.wattage > row.max_wattage
            },
            updateWattage: function(event, controller){
                controller.used_wattage = _.sumBy(controller.lightsources, 'wattage')
                this.totalWattage()
            },
            totalWattage(){
                let self = this
                let totalWattages = [];
                if(this.tableData.length>0) {
                    totalWattages = _.map(
                        _.groupBy(self.tableData, 'controller'),
                        (controller, name) => {
                            return {
                                name,
                                id: controller[0].controller_id+''+controller[0].product_id,
                                wattage: _.sumBy(controller, 'used_wattage')
                            }
                        }
                    );
                }

                this.$store.commit('supplies/setTotalWattage', totalWattages);
            }
        }
    }
</script>
