<template>
  <div class="table-container width-100 overlay-background" v-loading="loading">
    <div v-if="invoices && invoices.length > 0" class="row">
      <div v-for="(invoice, index) in invoices" :key="index" class="col-md-12">
        <a target="_blank" :href="'/account/invoices/' + invoice.No">
          <div class="table-row">
            <div class="col-xs-1 col-sm-1 col-md-1">
              <div class="list-item file-icon">
                <i class="fa fa-file-pdf" aria-hidden="true"></i>
              </div>
            </div>
            <div class="col-xs-9 col-sm-7 col-md-5">
              <div class="list-item title">{{ invoice.No }}</div>
            </div>
            <div class="hidden-xs hidden-sm col-md-3">
              <div class="list-item datum">
                {{ $t("date") }}: {{ formatDate(invoice.Posting_Date) }}
              </div>
            </div>
            <div class="hidden-xs col-sm-3 col-md-2">
              <div
                v-if="invoice.Remaining_Amount > 0"
                class="list-item red-text"
              >
                {{ $t("open") }}
              </div>
              <div v-else class="list-item green-text">{{ $t("payed") }}</div>
            </div>
            <div class="col-xs-1 col-sm-1 col-md-1">
              <div class="list-item download-icon">
                <i
                  class="far fa-arrow-to-bottom green-text"
                  aria-hidden="true"
                ></i>
              </div>
            </div>
          </div>
        </a>
      </div>
    </div>
    <div v-else-if="invoices" class="row">
      <div class="col-md-12">{{ $t("no_invoices") }}</div>
    </div>
    <div v-else class="row">
      <div class="col-md-12">
        {{ $t("backend_error") }}
      </div>
    </div>
    <div class="row" v-if="!noPagination && invoices && invoices.length > 0">
      <div class="col-md-12 text-center">
        <pagination
          v-model="page"
          :click-handler="updatePagination"
          :pageCount="pageCount"
          :container-class="'pagination'"
          :page-class="'page-item'"
          :page-link-class="'page-link-number'"
          :page-range="3"
          prev-text='<i class="fa fa-chevron-left"></i>'
          next-text='<i class="fa fa-chevron-right"></i>'
        ></pagination>

        <el-select
          class="page-size"
          v-model="limit"
          @change="changePageSize"
          :popper-append-to-body="false"
        >
          <el-option
            :key="index"
            v-for="(pageSize, index) in pageSizes"
            :value="pageSize"
          >
            {{ pageSize }}
          </el-option>
        </el-select>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import pagination from "vuejs-paginate";

export default {
  name: "Invoices",
  props: ["pageSizes", "noPagination", "pageSize", "translation"],
  components: {
    pagination,
  },
  data() {
    return {
      invoices: false,
      limit: 10,
      loading: false,
      pageCount: 0,
      page: 0,
    };
  },
  mounted() {
    if (this.pageSize) this.limit = this.pageSize;

    if (this.noPagination) this.getInvoices();
    else {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const params = Object.fromEntries(urlSearchParams.entries());
      if (params.pageSize) this.limit = params.pageSize;
      if (params.currentPage) this.page = parseInt(params.currentPage);

      this.getInvoices(params.currentPage);
    }
  },
  methods: {
    changePageSize() {
      this.page = 1;
      this.updatePagination(this.page);
    },
    updatePagination(page) {
      const urlParams = new URLSearchParams(window.location.search);
      urlParams.set("currentPage", page);
      urlParams.set("pageSize", this.limit);
      window.history.pushState(
        "invoice",
        "Invoices",
        "/account/invoices?" + urlParams
      );

      this.getInvoices(page);
    },
    async getInvoices(page = 1) {
      this.loading = true;
      let response = await Vue.http
        .get("/account/getInvoices", {
          params: {
            currentPage: page,
            pageSize: this.limit,
          },
        })
        .catch((e) => {
          this.loading = false;
          return false;
        });

      if (!response) {
        this.invoices = false;
        return false;
      }

      this.invoices = response.data.invoices;
      this.pageCount = response.data.pageCount;
      this.loading = false;
    },
    formatDate(date) {
      var datePart = date.match(/\d+/g), // get date parts and store as an array
        year = datePart[0], // get only two digits
        month = datePart[1],
        day = datePart[2];
      return day + "-" + month + "-" + year;
    },
  },
};
</script>

<style>
.page-size {
  position: absolute !important;
  width: 60px;
  right: 15px;
}
.page-size input {
  height: 35px !important;
  padding: 10px !important;
}
</style>
