<template>
    <el-form label-position="top">
        <div class="row">
            <div class="input-group">
                <div class="row">
                    <div class="col-lg-12">
                        {{ $t('based_on_total_power_of_lightsources') }} {{totalWattageCombined}}W {{ $t('we_advice') }}
                        <span v-if="recommendedSupplies.length == 1">
                            {{ $t('a') }} {{recommendedSupplies[0].wattage}}W LED {{ $t('supply') }}.
                        </span>
                        <span v-else>
                            {{ $t('the_following') }} LED {{ $t('supplies') }}:
                            <span :key="index" v-for="(supply, index) in recommendedSupplies">
                                {{supply.wattage}}W<span v-if="index+1 < recommendedSupplies.length">, </span><span v-else>.</span>
                            </span>
                        </span>
                        {{ $t('taken_into_account_20_margin') }}.
                    </div>
                </div>
            </div>

            <div class="input-group">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="input-number">
                            <el-form-item :label="$t('choose_one_or_more_supplies')" v-if="available_supplies.length > 0" class="row-3"  v-tooltip.top-start="tooltips.supplies">
                                <div class="eq-wh" :key="index" v-for="(supply, index) in available_supplies">
                                    <div class="content" v-if="minimalWattage(supply)">
                                        <div class="bg-image"
                                             :style="supply.image ? 'background-image: url(' + supply.image + ')' : 'background-image: url(/images/afbeelding-niet-gevonden.jpg)'"
                                        ></div>
                                        <div class="block-info">
                                            <h5>{{supply.description}}</h5>
                                        </div>
                                        <el-input-number class="product-amount" :value="0" v-model="supply.amount" :min="0" @change="updateSupplies(index)"></el-input-number>
                                    </div>
                                </div>
                            </el-form-item>
                            <div v-else>
                                {{ $t('no_products_found') }}..
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <el-alert v-if="tableData.totalUsedSources.length > 0"
                      :title="remainingRolls() ? table_alert_text : $t('chosen_supplies_are_sufficient')"
                      :type="remainingRolls() ? 'error' : 'success'">
            </el-alert>

            <supply-progress-table
                    :tableData="tableData"
                    :title1="$t('supplies')"
                    :title2="$t('combined_sources')"
                    :title3="$t('not_yet_sorted_sources')"
            ></supply-progress-table>

            <div class="row">
                <div class="col-xs-12">
                    <hr class="footer-separator" />
                </div>
                <div class="col-sm-6 text-center col-xs-12">
                    <el-button type="info" plain class="pull-left col-xs-12"
                               @click="nextCollapsePane(3)">
                        <span><i class="fa fa-chevron-left"></i> {{ $t('management') }}</span>
                    </el-button>
                </div>
                <div class="col-sm-6 text-center col-xs-12">
                    <el-button v-if="supplies.length > 0" type="primary" class="pull-left col-xs-12 xs-margintop-15"
                               @click="setSummary(true)">
                        <span>{{ $t('done_go_to_summary') }}</span>
                    </el-button>
                </div>
            </div>
        </div>
    </el-form>
</template>

<script>
    import {mapState} from 'vuex'
    import Vue from 'vue'
    import SupplyProgressTable from '../../SupplyProgressTable.vue'
    import _ from 'lodash'

    export default {
        name: 'Supplies',
        components: {SupplyProgressTable},
        props: ["tooltips", 'summary'],
        data () {
            return {
                available_supplies: [],
                waterproofs: [],
                waterproof: 'no',
                din_rails: [],
                din_rail: 'yes',
                recommendedSupplies: []
            }
        },
        watch: {
            din_rail: function (newValue, oldValue){
                if(newValue !== oldValue){
                    this.getSupplies()
                }
            },
            waterproof: function (newValue, oldValue){
                if(newValue !== oldValue){
                    this.getSupplies()
                }
            },
            lightsources: {
                handler: function (newValue, oldValue) {
                    this.getSupplies()
                },
                deep: true
            },
            totalWattage (newValue) {
                if(newValue.length > 0)
                    this.setRecommendedSupplies()
            }
        },
        computed:{
            ...mapState('language', {
                languageCode: state => state.language_code
            }),
            ...mapState('configurator', {
                mainValues: state => state.mainValues,
                pane: state => state.pane
            }),
            ...mapState('lightsources', {
                lightsources: state => _.map(state.lightsources, (lightsource) => _.pick(lightsource, ['length', 'led_strip']))
            }),
            ...mapState('supplies', {
                supplies: state => state.supplies,
                totalWattage: state => state.totalWattage
            }),
            tableData () {
                let self = this
                let tableData = []
                let usedTotalSources = []
                let totalWattages = _.orderBy(_.cloneDeep(this.totalWattage), ['wattage'], ['desc'])

                totalWattages = _.map(totalWattages, function(totalWattage){
                    totalWattage.used = 0
                    return totalWattage
                })

                for(let supply_index = 0; supply_index < self.supplies.length; supply_index++) {
                    for(let supply_amount_index = 1; supply_amount_index <= self.supplies[supply_index].amount; supply_amount_index++) {
                        let used_wattage = 0;
                        let max_wattage = self.supplies[supply_index].wattage;
                        let used_sources = [];

                        _.forEach(totalWattages, (source) => {
                            if((used_wattage+source.wattage > max_wattage*0.8) || source.used === 1)
                                return;

                            source.used = 1
                            used_sources.push(source)
                            usedTotalSources.push(source.id)
                            used_wattage = used_wattage+source.wattage;
                        })

                        tableData.push({
                            controller_id: supply_index+''+supply_amount_index,
                            controller: self.supplies[supply_index].product_name + '(' + supply_amount_index + ')',
                            max_wattage: max_wattage,
                            channel: 1,
                            no_of_channels: 1,
                            lightsources: used_sources, //map from used_lightsources -> reference
                            used_wattage: (_.sumBy(_.map(used_sources, 'wattage'), Number)).toFixed(2) //sum from used_lightsources -> wattage
                        })
                    }
                }



                return {
                    controllers: totalWattages,
                    totalUsedSources: usedTotalSources,
                    table: tableData.length > 0 ? tableData : [],
                    impossible: []
                }
            },
            totalWattageCombined(){
                let self = this
                return _.sumBy(_.map(self.totalWattage, 'wattage'), Number)
            }
        },
        mounted () {
            this.getSupplies()

            this.table_alert_text = 'Let op: met de gekozen voeding(en) heb je niet genoeg vermogen om alle samengestelde controllers te voeden.'
        },
        methods: {
            remainingRolls () {
                return (this.tableData.controllers.length-this.tableData.totalUsedSources.length) > 0
            },
            getSupplyFilterValues(){
                let self = this
                Vue.http.post(
                    '/led/getSupplyFilterValues',
                    {},
                    {
                        responseType: 'json'
                    }
                ).then(function (response) {
                    if (response.body) {
                        self.waterproofs = response.body.waterproof;
                        self.din_rails = response.body.din_rail;
                    }
                });
            },
            getSupplies(){
                this.getSupplyFilterValues()
                let self = this

                Vue.http.post(
                    '/led/get/supplies',
                    {
                        voltage: self.mainValues.voltage,
                        din_rail: self.din_rail,
                        waterproof: self.waterproof,
                        //minimal_wattage: _.first(_.orderBy(this.totalWattage, ['wattage'], ['asc'])) ? (_.first(_.orderBy(this.totalWattage, ['wattage'], ['asc']))).wattage : 0
                    },
                    {
                        responseType: 'json'
                    }
                ).then(function (response) {
                    if (response.data) {
                        self.available_supplies = _.map(response.data, function(supply){
                            supply.amount = 0;
                            return supply
                        });

                        if(self.available_supplies.length>0 && self.totalWattage.length > 0) {
                            self.setRecommendedSupplies()
                        }
                    }
                });
            },
            setRecommendedSupplies () {
                let self = this
                let biggest_supply = _.first(_.orderBy(self.available_supplies, ['wattage'], ['desc']))
                let totalWattageUntilMax = 0
                let maxWattages = []
                let totalWattages = _.cloneDeep(_.orderBy(self.totalWattage, ['wattage'], ['desc']))
                let totalAmount = _.sumBy(totalWattages, 'wattage')

                while(totalAmount > 0) {
                    totalWattageUntilMax = 0
                    _.forEach(totalWattages, (source) => {
                        if ((totalWattageUntilMax + source.wattage > biggest_supply.wattage * 0.8) || source.used === 1)
                            return;

                        source.used = 1
                        totalWattageUntilMax += source.wattage;
                        totalAmount -= source.wattage
                    })

                    if(totalWattageUntilMax > 0)
                        maxWattages.push(totalWattageUntilMax)
                    else
                        totalAmount = 0
                }

                self.$store.commit('supplies/setSupplies', [])
                self.recommendedSupplies = []
                _.forEach(maxWattages, (watt) => {
                    let set = false
                    _.forEach(self.available_supplies, (supply) => {
                        if (parseFloat(supply.wattage * 0.8) >= watt && !set) {
                            self.recommendedSupplies.push(supply)
                            set = true
                        }

                        supply.amount = 0
                    })
                })

                _.forEach(maxWattages, (watt) => {
                    self.updateSupplies(_.findIndex(self.available_supplies, function (supply) {
                        if (supply && parseFloat(supply.wattage*0.8) >= watt) {
                            if(supply.amount === 1)
                                supply.amount += 1
                            else
                                supply.amount = 1

                            return supply
                        }
                    }))
                })
            },
            updateSupplies(supply_id){
                let found_index = _.findIndex(this.supplies, {product_id: this.available_supplies[supply_id].product_id})

                if(found_index > -1){
                    if(this.available_supplies[supply_id].amount === 0)
                        this.supplies.splice(found_index, 1);
                    else
                        this.supplies[found_index].amount = this.available_supplies[supply_id].amount
                } else if (this.available_supplies[supply_id].amount !== 0) {
                    this.supplies.push({
                        "product_id": this.available_supplies[supply_id].product_id,
                        "product_name": this.available_supplies[supply_id].description,
                        "amount": this.available_supplies[supply_id].amount ? this.available_supplies[supply_id].amount : 1,
                        "wattage": this.available_supplies[supply_id].wattage,
                        "image": this.available_supplies[supply_id].image
                    })
                }
            },
            nextCollapsePane(value) {
                this.$store.commit('configurator/setPane', value)
                if(value === 3) {
                    setTimeout(function () {
                        $('html, body').animate({
                            scrollTop: $("#controller-parent").offset().top - 83
                        }, 500);
                    }, 300)
                }
            },
            setSummary(value){
                this.$emit('update:summary', value);
            },
            minimalWattage(item){
                if(this.totalWattage.length > 0)
                    return _.first(_.orderBy(this.totalWattage, ['wattage'], ['asc'])).wattage < item.wattage
            }
        }
    }
</script>
