<template>
  <div class="row overlay-background-mobile" v-loading="false">
    <div class="col-xs-12 col-sm-12 col-md-12">
      <div class="bg-wrapper width-100 overlay-background" v-if="(1==1)">
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-12">
            <h3 class="bold">{{ $t("delivery_address") }}</h3>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-12">
            <div class="width-100 md-form">
              <input
                id="invoiceInput"
                name=""
                type="text"
                class="form-control"
                aria-required="true"
                v-model="filterDelivery"
              />
              <label for="invoiceInput" class="input-label">
                {{ $t("filter_results") }}
              </label>
            </div>
          </div>
          <!-- vueper slides -->
          <div class="col-xs-12 col-sm-12 col-md-12">
            <carousel
              ref="addresses-carousel"
              :perPageCustom="[
              [0, 0.75],
                [350, 1],
                [577, 1.5],
                [769, 2.5],
                [1200, 3],
              ]"
              :navigateTo="navigateTo"
              :navigationEnabled="true"
              :paginationEnabled="false"
              :scrollPerPage="false"
              navigationPrevLabel="<div class='prev-next-slide'><i class='fas fa-chevron-left fa-2x'></i></div>"
              navigationNextLabel="<div class='prev-next-slide'><i class='fas fa-chevron-right fa-2x'></i></div>"
            >
              <slide
                :key="index"
                v-for="(address, index) in allAddresses"
                :title="address.Name"
              >
                <div
                  class="slider-item"
                  :class="{
                    active: activeAddress === address.Code,
                  }"
                  @click="setDeliveryAddress(address.Code)"
                >
                  <div class="row">
                    <div class="col-md-12">
                      <h5 class="bold">
                        {{ address.Name }}
                      </h5>
                    </div>
                    <div
                      class="col-xs-3 col-sm-3 col-md-3 text-center green-text"
                    >
                      <i class="fal fa-map-marker-alt fa-3x"></i>
                    </div>
                    <div class="col-xs-9 col-sm-9 col-md-9 text-wrapper">
                      {{ address.Address }}
                      <br />
                      {{ address.Address_2 }}
                      <br v-if="address.Address_2" />
                      {{ address.Post_Code }}
                      {{ formatCity(address.City) }}
                      <br />
                      {{ formatCountry(address.Country_Region_Code) }}
                      <br />
                    </div>
                  </div>
                </div>
              </slide>
              <slide v-if="addresses.count === 0">
                <div class="address-unit border-striped">
                  <h5 class="bold">
                    {{ $t("no_products_found") }}
                  </h5>
                </div>
              </slide>
              <slide v-if="addresses.count < 3">
                <div class="slider-item">
                  <div class="address-unit border-striped"></div>
                </div>
              </slide>
            </carousel>
          </div>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12">
          <button
            class="text-center button-white-green-slide"
            type="button"
            @click="openAddressesModalVisible"
          >
            {{ $t("other_delivery_address") }}
          </button>
        </div>
      </div>
      <div class="bg-wrapper width-100 overlay-background" v-else>
        <h5>{{ $t("error_in_api") }}</h5>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";

import { Carousel, Slide } from "vue-carousel";
export default {
  name: "AddressesSlider",
  components: { Carousel, Slide },
  props: {
    activeAddress: String,
    addresses: Array
  },
  data() {
    return {
      addresses: [],
      count: 0,
      loading: false,
      filterDelivery: "",
    };
  },
  mounted() {},
  computed: {
    navigateTo() {
      if (!this.filterDelivery) return 0;

      return [0, false];
    },
    allAddresses() {
      return this.addresses.filter((address) => {
        let found = false;
        if (!this.filterDelivery) return true;
        if (
          address.Name.toLowerCase().includes(
            this.filterDelivery.toLowerCase()
          ) ||
          address.City.toLowerCase().includes(
            this.filterDelivery.toLowerCase()
          ) ||
          address.Post_Code.toLowerCase().includes(
            this.filterDelivery.toLowerCase()
          ) ||
          address.Address.toLowerCase().includes(
            this.filterDelivery.toLowerCase()
          ) ||
          address.Address_2.toLowerCase().includes(
            this.filterDelivery.toLowerCase()
          )
        )
          found = true;

        return found;
      });
    },
  },
  updated() {},
  methods: {
    formatCity(city) {
      let city1, city2;
      city1 = city.toLowerCase();
      city2 = city1.charAt(0).toUpperCase() + city1.slice(1);
      return city2;
    },
    formatCountry(country) {
      switch (country) {
        case "NL":
          return "Nederland";
        case "DE":
          return "Duitsland";
        case "EN":
          return "Verenigd Koninkrijk";
        case "BE":
          return "Belgi\xEB";
        case "LU":
          return "Luxemburg";
        case "FR":
          return "Frankrijk";
        default:
          return "Land onbekend";
      }
    },
    openAddressesModalVisible() {
      this.$emit("openAddressesModal");
    },
    setDeliveryAddress(code) {
      this.$emit("update:activeAddress", code);
    },
  },
};
</script>