<template>
    <div>
        <div class="row">
            <div class="col-lg-12">
                <p v-if="available_remotes.length > 0">{{$t('you_can_select_a_remotecontrol')}}</p>
                <p v-else-if="available_remotes.length === 0">{{$t('you_cant_select_a_remotecontrol')}}</p>
            </div>
        </div>

        <el-form label-position="top" v-if="available_remotes.length > 0">
            <div class="row">
                <div class="col-lg-12">
                    <h5 class="subtitle" v-tooltip.top-start="tooltips.remote_control">{{$t('remote_choice')}}:</h5>
                </div>
            </div>
            <div class="input-group">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="input-number">
                            <el-form-item class="row-3">
                                <div class="eq-wh" :key="index" v-for="(remote, index) in available_remotes">
                                    <div class="content">
                                        <div class="bg-image"
                                             :style="remote.image ? 'background-image: url(' + remote.image + ')' : 'background-image: url(/images/afbeelding-niet-gevonden.jpg)'"
                                        ></div>
                                        <div class="block-info">
                                            <h5>{{remote.description}}</h5>
                                        </div>
                                        <el-input-number class="product-amount" :value="0" v-model="remote.amount" :min="0" @change="updateRemotes(index)"></el-input-number>
                                    </div>
                                </div>
                            </el-form-item>
                        </div>
                    </div>
                </div>
            </div>
        </el-form>
    </div>
</template>

<script>
    import _ from 'lodash'
    import Vue from 'vue'
    import {mapState} from 'vuex'

    export default {
        name: 'RemoteControlTab',
        props: ["tooltips"],
        data () {
            return {
                available_remotes: []
            }
        },
        computed:{
            ...mapState('language', {
                languageCode: state => state.language_code
            }),
            ...mapState('controller', {
                controller: state => state.controller,
                remotes: state => state.remotes
            })
        },
        watch: {
            'controller.product':  function (newValue, oldValue) {
                if(newValue !== oldValue)
                    this.getRemotes()
            }
        },
        mounted(){
            this.getRemotes()
        },
        methods: {
            getRemotes(){
                var self = this
                Vue.http.post(
                    '/led/get/remotes',
                    {
                        controller_id: self.controller.product.id
                    },
                    {
                        responseType: 'json'
                    }
                ).then(function (response) {
                    if (response.data) {
                        self.available_remotes = _.map(response.data, function(remote){
                            remote.amount = 0;
                            return remote
                        });
                    }
                });
            },
            updateRemotes(remote_id){
                let found_index = _.findIndex(this.remotes, {product_id: this.available_remotes[remote_id].product_id})

                if(found_index > -1){
                    if(this.available_remotes[remote_id].amount === 0)
                        this.remotes.splice(found_index, 1);
                    else
                        this.remotes[found_index].amount = this.available_remotes[remote_id].amount
                } else if (this.available_remotes[remote_id].amount !== 0)
                    this.remotes.push({
                        "product_id": this.available_remotes[remote_id].product_id,
                        "product_name": this.available_remotes[remote_id].description,
                        "amount": this.available_remotes[remote_id].amount,
                        "image": this.available_remotes[remote_id].image
                    })
            }
        }
    }
</script>
