<template>
  <div>
    <a href="#cd-nav" class="cd-nav-trigger" :class="action_bar_class">
      <span class="cd-nav-icon"></span>
    </a>
    <div id="cd-nav" class="cd-nav" :class="action_bar_class">
      <div class="cd-top-wrapper">
        <template v-if="isLoggedIn">
          
        </template>
      </div>
      <div class="cd-navigation-wrapper">
        <nav>
          <ul
            v-bind:class="{ has_active: active_menu }"
            class="navigation-levels"
          >
            <li class="navigation-level is-prev">
              <strong>{{ $t("product_groups") }}</strong>
              <ul class="menu">
                <li v-for="(menu_item, item_key) in prev.items" :key="item_key">
                  <a v-if="menu_item.childs" @click="goToNext(menu_item)"
                    >{{ menu_item.name }}
                    <i v-if="menu_item.childs" class="fa fa-chevron-right"></i
                  ></a>
                  <a v-else v-bind:href="'/' + menu_item.url"
                    >{{ menu_item.name }}
                  </a>
                </li>
              </ul>
              <strong>{{ $t("remaining") }}</strong>
              <ul class="menu">
                <li>
                  <a href="/themas"
                    >{{ $t("segments") }}</a>
                </li>
                <li>
                  <a href="/blog"
                    >{{ $t("blog") }}</a>
                </li>
                <li>
                  <a href="/winkel"
                    >{{ $t("webshop") }}</a>
                </li>
                <li>
                  <a href="/klantenservice"
                    >{{ $t("help_and_contact") }}</a>
                </li>
                <li>
                  <a href="/klantenservice/klant-worden"
                    >{{ $t("become_a_client") }}</a>
                </li>
                <li>
                  <a v-if="!isLoggedIn" href="#" class="dropdown-toggle myaccount">
                    {{ $t("my_account") }} <i class="fa fa-user"></i>
                  </a>
                  <a v-else @click="goToNext(account)">
                    {{ $t("my_account") }} <i class="fa fa-user"></i>
                  </a>
                </li>
                <li>
                  <a href="/offertelijst"
                    >{{ $t("shopping_cart") }} <i class="fa fa-shopping-cart"></i
                  ></a>
                </li>
              </ul>
            </li>
            <li class="navigation-level is-current">
              <strong v-if="!current.parent">{{ $t("product_groups") }}</strong>
              <strong v-else @click="goToPrev()"
                ><i class="fa fa-chevron-left"></i> 
                <template v-if="current.parent.url == 'account'">
                  {{ $t("back_to_menu") }}
                </template>
                <template v-else>
                  {{ $t("all_product_groups") }}
                </template>                
              </strong>
              <ul class="menu">
                <li v-if="current.parent">
                  <a v-bind:href="'/' + current.parent.url">
                    <template v-if="current.parent.url == 'account'">
                      {{ current.parent.name }}
                    </template>
                    <template v-else>
                      {{ $t("all") }} {{ current.parent.name }}
                    </template>                    
                  </a>
                </li>
                <li
                  v-for="(menu_item, menu_item_key) in current.items"
                  :key="menu_item_key"
                  v-bind:class="{ active: menu_item.active }"
                >
                  <a v-if="menu_item.childs" @click="goToNext(menu_item)"
                    >{{ menu_item.name }}
                    <i v-if="menu_item.childs" class="fa fa-chevron-right"></i
                  ></a>
                  <a v-else v-bind:href="'/' + menu_item.url">{{
                    menu_item.name
                  }}</a>
                </li>
              </ul>
              <strong v-if="!current.parent">{{ $t("remaining") }}</strong>
              <ul class="menu" v-if="!current.parent">
                <li>
                  <a href="/themas"
                    >{{ $t("segments") }}</a>
                </li>
                <li>
                  <a href="/blog"
                    >{{ $t("blog") }}</a>
                </li>
                <li>
                  <a href="/winkel"
                    >{{ $t("webshop") }}</a>
                </li>
                <li>
                  <a href="/klantenservice"
                    >{{ $t("help_and_contact") }}</a>
                </li>
                <li>
                  <a href="/klantenservice/klant-worden"
                    >{{ $t("become_a_client") }}</a>
                </li>
                <li>
                  <a v-if="!isLoggedIn" href="#" class="dropdown-toggle myaccount">
                    {{ $t("my_account") }} <i class="fa fa-user"></i>
                  </a>
                  <a v-else @click="goToNext(account)">
                    {{ $t("my_account") }} <i class="fa fa-user"></i>
                  </a>
                </li>
                <li>
                  <a href="/offertelijst"
                    >{{ $t("shopping_cart") }} <i class="fa fa-shopping-cart"></i
                  ></a>
                </li>
              </ul>
            </li>
            <li class="navigation-level is-next">
              <strong v-if="next.parent" @click="goToPrev()"
                ><i class="fa fa-chevron-left"></i> {{ $t("all_product_groups") }}</strong
              >
              <ul class="menu">
                <li v-if="next.parent">
                  <a v-bind:href="'/' + next.parent.url"
                    >{{ $t("all") }} {{ next.parent.name }}</a
                  >
                </li>
                <li
                  v-for="(menu_item, menu_item_key) in next.items"
                  :key="menu_item_key"
                  v-bind:class="{ active: menu_item.active }"
                >
                  <a v-bind:href="'/' + menu_item.url"
                    >{{ menu_item.name }}
                    <i v-if="menu_item.childs" class="fa fa-chevron-right"></i
                  ></a>
                </li>
              </ul>
            </li>
          </ul>
        </nav>
      </div>
      <!-- .cd-navigation-wrapper -->
    </div>
    <!-- .cd-nav -->
    <div class="cd-nav-mask" @click="toggleNav" :class="action_bar_class"></div>
  </div>
</template>

<script>
import {mapState} from 'vuex'

export default {
  name: "MobNav",
  props: ["menu", "action_bar_class", "store_ext"],
  data() {
    return {
      is_animating: false,
      active_menu: null,
      prev: [],
      current: [],
      next: [],
      account: {
        name: this.$t("my_account"),
        url: "account",
        childs: {
          parent: {
            name: this.$t("my_account"),
            url: "account",
          },
          items: [
            {
              name: this.$t("current_orders"),
              url: "account/orders",
            },
            {
              name: this.$t("invoices"),
              url: "account/invoices",
            },
            {
              name: this.$t("pricelist"),
              url: "account/pricelist",
            },
            {
              name: this.$t("company_info"),
              url: "account/companies",
            },
            {
              name: this.$t("users"),
              url: "account/users",
            },
            {
              name: this.$t("logout"),
              url: "login/logout",
            },
          ],
        },
      },
    };
  },
  computed: {
    ...mapState('header_cart', {
        itemCount: state => state.itemCount,
        isLoggedIn: state => state.isLoggedIn,
    }),
  },
  mounted() {
    var self = this;

    $(".cd-nav-trigger").on("click", function (event) {
      event.preventDefault();
      self.toggleNav();
    });

    var current_url = window.location.pathname.substr(1);

    if (current_url.indexOf("/") > -1) {
      current_url = current_url.substring(0, current_url.indexOf("/"));
    }

    var found = false;

    $.each(self.menu.items, function (key, item) {
      if (item.url == current_url) {
        if (item.childs) {
          found = true;
        } else {
          item.active = true;
        }
      }

      if (item.childs) {
        $.each(item.childs.items, function (subkey, subitem) {
          if (subitem.url == current_url) {
            found = true;
            subitem.active = true;
          }
        });
      }

      if (found) {
        self.current = item.childs;
        self.prev = self.menu;
        return false;
      }
    });

    if (!found) {
      this.current = this.menu;
    }
  },
  methods: {
    toggleNav: function () {
      if (!this.is_animating) {
        if ($(this).parents(".csstransitions").length > 0)
          this.is_animating = true;

        $("#chat-widget-container").toggleClass("hidden");

        $("body").toggleClass("navigation-is-open");
        $(".cd-nav-mask").toggleClass("is-active");
        $(".cd-navigation-wrapper").one(
          "webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend",
          function () {
            //animation is over
            this.is_animating = false;
          }
        );
      }
    },
    setActive: function (menu_item) {
      this.active_menu = menu_item.url;
    },
    goToNext: function (menu_item) {
      var self = this;
      self.prev = self.current;
      self.next = menu_item.childs;

      $(".cd-navigation-wrapper").scrollTop(0);

      $(".cd-nav nav li.is-current").addClass("animate");
      $(".cd-nav nav li.is-next").addClass("animate");
      $(".cd-nav nav").addClass("goto-next");

      $(".cd-nav nav li.is-next").one(
        "webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend",
        function () {
          //animation is over
          self.current = menu_item.childs;

          $(".cd-nav nav").removeClass("goto-next");
          $(".cd-nav nav li.is-current").removeClass("animate");
          $(".cd-nav nav li.is-next").removeClass("animate");
        }
      );
    },
    goToPrev: function () {
      var self = this;

      $(".cd-nav nav li.is-current").addClass("animate");
      $(".cd-nav nav li.is-prev").addClass("animate");
      $(".cd-nav nav").addClass("goto-prev");

      $(".cd-nav nav li.is-prev").one(
        "webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend",
        function () {
          //animation is over
          self.current = self.prev;

          $(".cd-nav nav").removeClass("goto-prev");
          $(".cd-nav nav li.is-current").removeClass("animate");
          $(".cd-nav nav li.is-prev").removeClass("animate");
        }
      );
    },
  },
};
</script>
