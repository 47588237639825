<template>
  <div>
    <div v-if="orderlines != null">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12">
          <h4>
            {{ $t("article_overview") }}
          </h4>
        </div>
      </div>
      <div class="bg-wrapper background width-100">
        <div class="row" v-loading="loading">
          <div class="col-xs-12 col-sm-12 col-md-12">
            <div class="row article-overview-header">
              <div class="col-xs-8 col-sm-8 col-md-6">
                <div class="list-item">
                  <h5>{{ $t("description") }}</h5>
                </div>
              </div>
              <div class="hidden-xs hidden-sm col-md-2">
                <div class="list-item">
                  <h5>{{ $t("ordered") }}</h5>
                </div>
              </div>
              <div class="hidden-xs hidden-sm col-md-2">
                <div class="list-item">
                  <h5>{{ $t("to_supply") }}</h5>
                </div>
              </div>
              <div class="hidden-xs hidden-sm col-md-2">
                <div class="list-item text-right">
                  <h5>{{ $t("price") }}</h5>
                </div>
              </div>
              <div class="col-xs-4 col-sm-4 hidden-md hidden-lg">
                <div class="list-item text-right">
                  <h5>{{ $t("total") }}</h5>
                </div>
              </div>
            </div>
            <hr class="article-overview-hr" />
            <div class="row" :key="index" v-for="(item, index) in orderlines">
              <div class="col-xs-8 col-sm-8 col-md-6">
                <div class="list-item green-text">
                  {{ item.Description }}
                </div>
                <div class="list-item">
                  <div class="row">
                    <div class="col-xs-8 col-sm-8 col-md-6">
                      <div class="grey-text">{{ $t("supply_amount") }}:</div>
                    </div>
                    <div class="col-xs-4 col-sm-4 col-md-6">
                      <div
                        class="grey-text"
                        v-if="item.Unit_of_Measure_Code != ''"
                      >
                        {{ item.Unit_of_Measure_Code }}
                      </div>
                      <div class="grey-text" v-else>-</div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xs-8 col-sm-8 col-md-6">
                      <div class="grey-text">
                        {{ $t("article_number_short") }}:
                      </div>
                    </div>
                    <div class="col-xs-4 col-sm-4 col-md-6">
                      <div class="grey-text" v-if="item.No != ''">
                        {{ item.No }}
                      </div>
                      <div class="grey-text" v-else>-</div>
                    </div>
                  </div>
                </div>
                <div class="list-item hidden-md hidden-lg">
                  <div>
                    {{ $t("ordered") }}:
                    <!-- <?= $orderline->Quantity ?> -->
                    {{ item.Quantity }}
                    {{ item.Unit_of_Measure }}
                  </div>
                  <div>
                    {{ $t("to_supply") }}:
                    {{ (item.Quantity - item.Quantity_Shipped) ? item.Quantity - item.Quantity_Shipped : $t("delivered") }}
                  </div>
                </div>
                <div class="list-item" v-if="(item.Quantity - item.Quantity_Shipped) > 0">
                  <div v-if="item.Shipment_Date != '0001-01-01'">
                    {{ $t("send_date") }}:
                    {{ formatDate(item.Shipment_Date) }}
                  </div>
                  <div v-else>
                    {{ $t("send_date") }}:
                    {{ $t("on_request") }}
                  </div>
                  <div
                    v-if="
                      item.Quantity > Quantity_Shipped &&
                      item.Exp_Delivery_Time_Back_Order != '0001-01-01'
                    "
                  >
                    {{ $t("send_date") }}
                    {{ $t("backorder") }}:
                    {{ formatDate(item.Exp_Delivery_Time_Back_Order) }}
                  </div>
                </div>
              </div>
              <div class="hidden-xs hidden-sm col-md-2">
                <div class="list-item">
                  {{ item.Quantity }}
                </div>
                <div class="list-item">
                  {{ item.Unit_of_Measure }}
                </div>
              </div>
              <div class="hidden-xs hidden-sm col-md-2">
                <div class="list-item">
                  {{ (item.Quantity - item.Quantity_Shipped) ? item.Quantity - item.Quantity_Shipped : $t("delivered") }}
                </div>
                <div class="list-item">
                  {{ (item.Quantity - item.Quantity_Shipped) ? item.Unit_of_Measure : '' }}
                </div>
              </div>
              <div class="col-xs-4 col-sm-4 col-md-2">
                <div class="list-item text-right">
                  {{ item.Line_Discount }}%
                </div>
                <div class="list-item text-right">
                  &euro;
                  {{ item.Line_Amount }}
                </div>
              </div>
              <div class="col-md-12">
                <hr />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="row"
        v-if="orderlines && orderlines.length > 0 && !checkFullscreen"
      >
        <div class="col-md-12 text-center">
          <pagination
            v-model="page"
            :click-handler="updatePagination"
            :pageCount="pageCount"
            :container-class="'pagination'"
            :page-class="'page-item'"
            :page-link-class="'page-link-number'"
            :page-range="3"
            prev-text='<i class="fa fa-chevron-left"></i>'
            next-text='<i class="fa fa-chevron-right"></i>'
          ></pagination>

          <el-select
            class="page-size"
            v-model="limit"
            @change="changePageSize"
            :popper-append-to-body="false"
          >
            <el-option
              :key="index"
              v-for="(pageSize, index) in pageSizes"
              :value="pageSize"
            >
              {{ pageSize }}
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="row" v-if="checkFullscreen">
        <div class="col-xs-12 col-sm-12 hidden-md hidden-lg">
          <button
            class="button-white-green-slide width-100 margin-bottom-25"
            @click="showAll(1)"
          >
            <div class="text-center bold">&#65086;{{ $t("show_all") }}</div>
          </button>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="bg-wrapper background width-100">
        {{ $t("error_in_api") }}
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import pagination from "vuejs-paginate";

export default {
  props: ["orderNo", "pageSizes", "noPagination", "pageSize", "translation"],
  name: "OrderInfo",
  components: {
    pagination,
  },
  data() {
    return {
      orderlines: false,
      limit: 5,
      noPagination: false,
      loading: false,
      pageCount: 0,
      page: 0,
    };
  },
  computed: {
    checkFullscreen() {
      return window.outerWidth <= 767;
    },
  },
  mounted() {
    if (this.pageSize) this.limit = this.pageSize;

    if (this.noPagination) this.getOrders();
    else {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const params = Object.fromEntries(urlSearchParams.entries());
      if (params.orderId) this.orderNo = params.orderId;
      if (params.pageSize) this.limit = params.pageSize;
      if (params.currentPage) this.page = parseInt(params.currentPage);

      this.getOrders(params.currentPage);
    }
  },
  methods: {
    showAll(page = 1) {
      this.updatePagination(page, this.limit * this.pageCount);
    },
    changePageSize() {
      this.page = 1;
      this.updatePagination(this.page);
    },
    updatePagination(page, limit = this.limit) {
      this.loading = true;
      const urlParams = new URLSearchParams(window.location.search);
      urlParams.set("orderId", this.orderNo);
      urlParams.set("currentPage", page);
      urlParams.set("pageSize", limit);
      window.history.pushState(
        "order",
        "Orders",
        "/account/orders/" + this.orderNo + "?" + urlParams
      );
      this.getOrders(page, limit);
    },
    async getOrders(page = 1, limit = 5) {
      this.loading = true;
      let response = await Vue.http
        .get("/account/orderItem", {
          params: {
            orderId: this.orderNo,
            currentPage: page,
            pageSize: limit,
          },
        })
        .catch((e) => {
          this.loading = false;
          return false;
        });

      if (!response) {
        this.orderlines = false;
        this.loading = false;
        return false;
      }
      this.orderlines = response.data.orderlines;
      this.pageCount = response.data.pageCount;
      this.loading = false;
    },
    formatDate(date) {
      var datePart = date.match(/\d+/g), // get date parts and store as an array
        year = datePart[0], // get only two digits
        month = datePart[1],
        day = datePart[2];
      return day + "-" + month + "-" + year;
    },
  },
};
</script>

<style>
.page-size {
  position: absolute !important;
  width: 60px;
  right: 15px;
}
.page-size input {
  height: 35px !important;
  padding: 10px !important;
}
</style>
