import Vue from 'vue'

const state = {
    lightsources: [{
        activeName: "led_strip",
        reference: null,
        length: 0,
        powers:[],
        colour_choices: [],
        led_strip: {
            valid: false,
            product: {},
            amount: 0
        },
        led_profile: {
            product: {},
            amount: 0,
            lens: ''
        },
        accessories: []
    }]
}

const getters = {}

const actions = {
}

const mutations = {
    setLightsourceTab(state, data){
        Vue.set(state.lightsources[0], 'activeName', data);
    },
    setLedstrip(state, data){
        Vue.set(state.lightsources[data.index].led_strip, 'product', data.product);
        if(data.product){
            Vue.set(state.lightsources[data.index].led_strip, 'valid', true);
        } else {
            Vue.set(state.lightsources[data.index].led_strip, 'valid', false);
        }

    },
    setLedprofile(state, data){
        Vue.set(state.lightsources[data.index].led_profile, 'product', data.product);
        Vue.set(state.lightsources[data.index].led_profile, 'lens', '');
    }
}

export default {
    namespaced:true,
    state,
    getters,
    actions,
    mutations
}
