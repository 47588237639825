<template>
  <el-dialog
    :visible.sync="visible"
    :before-close="handleClose"
    :fullscreen="fullScreen"
    width="600px"
    class="new-addresses-modal"
  >
    <span slot="title" class="dialog-header">
      <span class="el-dialog__title">
        {{ $t("new_delivery_address") }}
      </span>
    </span>

    <div class="modal-body">
      <el-form
        class="form-group"
        ref="form"
        :model="form"
        :rules="rules"
        label-position="top"
        v-if="!submitted"
      >
        <div class="form-group">
          <div class="row">
            <div class="col-md-6">
              <el-form-item class="md-form" prop="companyname">
                <el-input
                  :placeholder="$t('companyname')"
                  v-model="form.companyname"
                ></el-input>
              </el-form-item>
            </div>
            <div class="col-md-6">
              <el-form-item class="md-form" prop="country">
                <el-select :placeholder="$t('country')" v-model="form.country">
                  <el-option value="NL" label="Nederland"></el-option>
                  <el-option value="BE" label="België"></el-option>
                  <!-- <el-option value="3" label="Duitsland"></el-option> -->
                  <!-- <el-option value="4" label="Verenigd Koninkrijk"></el-option> -->
                </el-select>
              </el-form-item>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <el-form-item class="md-form" prop="zipcode">
                <el-input
                  :placeholder="$t('zipcode')"
                  v-model="form.zipcode"
                ></el-input>
              </el-form-item>
            </div>
            <div class="col-md-6">
              <el-form-item class="md-form" prop="city">
                <el-input
                  :placeholder="$t('city')"
                  v-model="form.city"
                ></el-input>
              </el-form-item>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <el-form-item class="md-form" prop="address">
                <el-input
                  :placeholder="$t('address')"
                  v-model="form.address"
                ></el-input>
              </el-form-item>
            </div>
            <!-- <div class="col-md-6">
              <el-form-item class="md-form" prop="street">
                <el-input
                  :placeholder="$t('street')"
                  v-model="form.street"
                ></el-input>
              </el-form-item>
            </div> -->
          </div>
          <!-- <div class="row">
            <div class="col-xs-2 col-sm-2 col-md-12">
              <el-checkbox v-model="form.default_delivery">{{
                $t("default_delivery_address")
              }}</el-checkbox>
            </div>
          </div> -->
        </div>
      </el-form>

      <div class="row" v-if="serverResponse">
        <div class="col-md-12">
          <p :class="{'error': !serverResponse.status, 'success': serverResponse.status}">{{serverResponse.response}}</p>
        </div>
      </div>
    </div>

    <span slot="footer" class="dialog-footer">
      <div class="row" v-if="!serverResponse">
        <div class="col-xs-6 col-sm-6 col-md-6">
          <el-button
            type="button"
            class="button-white-slide width-100"
            @click="handleClose"
          >
            {{ $t("cancel") }}
          </el-button>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-6">
          <el-button
            type="button"
            class="button-green-slide width-100"
            :loading="loading"
            @click="saveAddress"
          >
            {{ $t("submit") }}
          </el-button>
        </div>
      </div>
      <div class="row" v-else="serverResponse">
        <div class="col-xs-6 col-sm-6 col-md-6">
          <el-button
            type="button"
            class="button-white-slide width-100"
            @click="handleClose"
          >
            {{ $t("close") }}
          </el-button>
        </div>
      </div>
    </span>
  </el-dialog>
</template>

<script>
import Vue from "vue";

export default {
  name: "NewAddressesModal",
  props: { visible: Boolean, fullScreen: Boolean },
  data() {
    return {
      serverResponse: null,
      form: {
        companyname: "",
        country: "",
        zipcode: "",
        city: "",
        address: ""
      },
      rules: {
        companyname: [
          {
            required: true,
            message: this.$t("please_fill_a_companyname"),
            trigger: "blur",
          },
        ],
        country: [
          {
            required: true,
            message: this.$t("please_select_a_country"),
            trigger: "blur",
          },
        ],
        zipcode: [
          {
            required: true,
            message: this.$t("please_fill_a_zipcode"),
            trigger: "blur",
          },
        ],
        address: [
          {
            required: true,
            message: this.$t("please_fill_an_address"),
            trigger: "blur",
          },
        ],
        city: [
          {
            required: true,
            message: this.$t("please_fill_a_city"),
            trigger: "blur",
          },
        ],
      },
      loading: false,
      submitted: false,
    };
  },
  computed: {},
  methods: {
    handleClose() {
      this.$emit("closeDialog");
      this.loading = false;
      this.form = {
        companyname: "",
        country: "",
        zipcode: "",
        city: "",
        address: ""
      }
    },
    saveAddress() {
      let self = this;
      this.loading = true;
      this.$refs["form"].validate((valid) => {
        if (valid) {
          Vue.http
            .post("/account/addAddress", this.form)
            .then(function (response) {
              if (response && response.data) {
                self.serverResponse = response.data;
                self.loading = false;
              }
            });
        } else {
          self.loading = false;
          return false;
        }
      });
    },
  },
};
</script>

<style>
.new-addresses-modal .error {
  color: #f44336; 
}
.new-addresses-modal .success {
 color: #4caf50;
}
</style> 