import { render, staticRenderFns } from "./RemoteControlTab.vue?vue&type=template&id=3df352dd&"
import script from "./RemoteControlTab.vue?vue&type=script&lang=js&"
export * from "./RemoteControlTab.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "RemoteControlTab.vue"
export default component.exports