<template>
  <section
    id="thanks"
    class="background overlay-background-mobile no-padding-bottom"
  >
    <div class="container">
      <div class="row">
        <div class="hidden-xs hidden-sm col-md-2 col-lg-2"></div>
        <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8 overlay-background">
          <div class="row">
            <div class="col-xs-12 col-md-12">
              <h1 class="text-center green-text">
                <i class="fas fa-check-circle"></i>
              </h1>
            </div>
          </div>
          <div class="row mb-30">
            <div class="col-xs-12 col-md-12">
              <h2 class="bold text-center">
                {{$t('thanks_for_ordering')}}
                
              </h2>
            </div>
          </div>
          <div class="row mb-30">
            <div class="col-xs-12 col-md-12">
              <p class="text-center width-80 ml-10">
                {{$t('when_order_processed')}}
              </p>
            </div>
          </div>
          <div class="row mb-30">
            <div class="col-xs-12 col-md-12" v-loading="loading">
              <a :href="'/account/orders'">
                <button type="button" class="button-green-slide center">
                  {{$t('go_to_orders')}}
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Vue from "vue";
export default {
  name: "Thanks",
  components: {},
  props: {},
  data() {
    return {
      estimate: {},
      deliveryAddresses: [],
      isLoggedIn: false,
      loading: false,
      orderID: "",
      referenceNumber: "",
      activeDeliveryAddress: null,
      addressesModalVisible: false,
      newAddressModalVisible: false,
    };
  },
  computed: {
    checkFullscreen() {
      return window.screen.width <= 992;
    },
  },
  mounted() {
    this.getOrders();
    // this.getItems();
  },
  methods: {
    async getOrders() {
      // this.loading = true;
      let response = await Vue.http
        .get("/account/getOrders", {
          params: {
            currentPage: 1,
            pageSize: 1000,
          },
        })
        .catch((e) => {
          console.log(e);
          // this.loading = false;
          return '';
        });

      if (!response) return '';
      this.orderID = response.data.orders[response.data.pageCount].No;
      // this.loading = false;
    },

    // async getItems() {
    //   let response = await Vue.http.get("/offertelijst/get");
    //   if (!response) return false;
    //   console.log(response);
    //   this.orderID = response.body.order_id;
    // },
  },
};
</script>

<style>
#shopping-cart .side-bar {
  margin-bottom: 0 !important;
}
#shopping-cart .no-vertical-margin {
  margin-top: -10px !important;
}
</style>
