<template>
    <div v-if="tableData.table">

        <div v-if="hasWarning">
            <br />
            <el-alert
                    :title="$t('unrecommended_usage_above_80_supply')"
                    :type="'warning'">
            </el-alert>
        </div>

        <el-table :data="tableData.table"
                  v-if="tableData.table.length>0"
                  :span-method="objectSpanMethod"
                  :empty-text="$t('no_selected_lightsources')"
                  class="power-table supply-table"
                  border style="width: 100%; margin-top: 20px">
            <el-table-column
                    :label="title1 ? title1 : ''">
                <el-table-column
                        prop="controller"
                        :label="$t('productname')">
                </el-table-column>
                <el-table-column
                        prop="max_wattage"
                        :label="'Max. ' + $t('wattage')"
                        width="120">
                    <template slot-scope="scope">
                        {{scope.row.max_wattage}}W
                    </template>
                </el-table-column>
            </el-table-column>
            <el-table-column :label="title2 ? title2 : ''">
                <el-table-column
                        prop="lightsources"
                        :label="$t('reference')">
                    <template slot-scope="scope">
                        <el-select v-model="scope.row.lightsources" @change="updateWattage($event, scope.row)"
                                   :popper-append-to-body="false"
                                   :no-data-text="$t('all_controllers_suppliers_classified')"
                                   multiple placeholder="Select" value-key="id">
                            <el-option
                                    v-for="item in notSelected(scope.row)"
                                    :disabled="isPossible(scope.row, item)"
                                    :key="item.name"
                                    :label="item.name"
                                    :value="item">
                                {{item.name}} {{item.wattage}}W
                            </el-option>
                        </el-select>
                    </template>
                </el-table-column>
                <el-table-column :label="$t('wattage')" width="120"
                                 prop="used_wattage">
                    <template slot-scope="scope">
                        {{parseFloat(scope.row.used_wattage).toFixed(0)}}W
                        <el-button class="pull-right" type="warning" size=mini round v-if="(scope.row.used_wattage / scope.row.max_wattage * 100) > 80">{{parseInt((scope.row.used_wattage / scope.row.max_wattage * 100))}}%</el-button>
                        <el-button class="pull-right" type="success" size=mini round v-else>{{parseInt((scope.row.used_wattage / scope.row.max_wattage * 100))}}%</el-button>
                    </template>
                </el-table-column>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
    import _ from 'lodash'

    export default {
        name: 'SupplyProgressTable',
        props: ['tableData', 'title1', 'title2', 'title3'],
        data (){
            return {
                usedPercentages: []
            }
        },
        computed:{
            hasWarning () {
                return _.max(this.usedPercentages) > 80
            }
        },
        methods: {
            setUsedPercentages(controller) {
                controller.used_percentage = (controller.used_wattage / controller.max_wattage * 100)
            },
            objectSpanMethod({ row, column, rowIndex, columnIndex }) {
                if (columnIndex === 0) {
                    if(row.channel === 1) {
                        return {
                            rowspan: row.no_of_channels,
                            colspan: 1
                        }
                    } else {
                        return {
                            rowspan: 0,
                            colspan: 0
                        };
                    }
                }
            },
            notSelected (row) {
                let available = _.cloneDeep(this.tableData.controllers);
                _.each(this.tableData.table, function(supply) {
                    if (row.controller_id + '' + row.channel !== supply.controller_id + '' + supply.channel) {
                        available = _.pullAllBy(available, supply.lightsources, 'id')
                    }
                });
                return available;
            },
            isPossible (row, controller){
                let selected = _.findIndex(row.lightsources, {id: controller.id}) > -1;
                return !selected && parseFloat(row.used_wattage)+parseFloat(controller.wattage) > parseFloat(row.max_wattage)
            },
            updateWattage: function(event, controller){
                controller.used_wattage = _.sumBy(controller.lightsources, 'wattage')
                this.setUsedPercentages(controller);
            }
        }
    }
</script>
