<template>
    <div class="row">
        <div class="col-lg-12">
            <el-form ref="mainValues" :model="mainValues" label-position="top">
                <div class="row">
                    <div class="input-group">
                        <div class="col-lg-8 col-md-8 col-sm-12">
                            <el-form-item :label="$t('what_is_your_led_config_reference')" v-tooltip.top-start="tooltips.group_reference">
                                <el-input :placeholder="$t('led_config_example')" @input="updateGroup" :label="$t('what_is_your_led_config_reference')" name="referentie-config" id="referentie-config"></el-input>
                            </el-form-item>
                        </div>
                    </div>
                </div>
                <div class="input-group">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="radio-image small">
                                <el-form-item :label="$t('what_lightcolour')" v-tooltip.top-start="tooltips.light_colour">
                                    <el-radio-group v-model="mainValues.light_colour" size="small">
                                        <el-radio :label="index" v-for="(light_colour, index) in light_colours" :key="index">
                                            <img :src="'/images/'+index+'.jpg'">
                                            <span class="option-name">{{light_colour}}</span>
                                        </el-radio>
                                    </el-radio-group>
                                </el-form-item>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="input-group">
                    <div class="row">
                        <div class="col-lg-4 col-md-6 col-sm-12">
                            <el-form-item :label="$t('ip_value')" v-tooltip.top-start="tooltips.IP_value">
                                <el-select v-model="mainValues.ip_value" :popper-append-to-body="false" :placeholder="$t('ip_value')">
                                    <el-option
                                            v-for="(item, index) in ip_values"
                                            :key="index"
                                            :label="item"
                                            :value="index">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </div>
                    </div>
                </div>
            </el-form>
        </div>

        <div class="row">
            <div class="col-lg-12">
                <hr class="footer-separator" />

                <el-button @click="nextCollapsePane(2)" type="primary" class="next-step pull-right col-xs-12 col-sm-6">
                    <span>{{$t('configure_lightsources')}} <i class="fa fa-chevron-right"></i></span>
                </el-button>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex'
    export default {
        name: 'Group',
        props: ["tooltips", "light_colours", "voltages", "ip_values"],
        computed:{
            ...mapState('configurator', {
                mainValues: state => state.mainValues,
                group: state => state.group,
                pane: state => state.pane
            }),
            ...mapState('language', {
                languageCode: state => state.language_code
            })
        },
        mounted(){
        },
        methods: {
            nextCollapsePane(value) {
                this.$store.commit('configurator/setPane', value)
                setTimeout(function() {
                    $('html, body').animate({
                        scrollTop: $("#lightsources-parent").offset().top-83
                    }, 500);
                }, 301)
            },
            updateGroup(value){
                this.$store.commit('configurator/setGroup', value)
            }
        }
    }
</script>