<template>
    <div>
        <el-form>
            <div class="row">
                <div class="col-md-3">
                    {{$t('filter_controllers')}}
                </div>
                <div class="col-md-3">
                    <el-form-item :label="$t('dimmable')">
                        <el-select clearable @change="getControllers" v-model="dimmable" :popper-append-to-body="false" :placeholder="$t('dimmable')">
                            <el-option
                                    v-for="(dimmable, index) in dimmables"
                                    :key="index"
                                    :label="dimmable"
                                    :value="index">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </div>
                <div class="col-md-3">
                    <el-form-item v-if="mainValues.light_colour === 'single_colour'" :label="$t('control_type')">
                        <el-select clearable @change="getControllers" v-model="control" :popper-append-to-body="false" :placeholder="$t('control')">
                            <el-option
                                    v-for="(control, index) in controls"
                                    :key="index"
                                    :label="control"
                                    :value="index">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <p v-else-if="mainValues.light_colour === 'rgb'">RGB</p>
                    <p v-else>RGBW</p>
                </div>
            </div>
            <hr class="filter-separator" />
        </el-form>
        <div class="row">
            <div class="input-group">
                <div class="col-lg-12">
                    <div class="radio-image row-4" v-if="products.length > 0">
                        <el-radio-group :value="controller.product">
                            <el-radio :label="prod" v-for="prod in products" :key="prod.id" @change="setProduct(prod)">
                                <div class="bg-image"
                                     :style="prod.image ? 'background-image: url(' + prod.image + ')' : 'background-image: url(/images/afbeelding-niet-gevonden.jpg)'"
                                ></div>
                                <div class="block-info">
                                    <ul>
                                        <li>{{prod.product_name}}</li>
                                        <li>{{prod.voltage}} </li>
                                        <li class="link"><a target="_blank" :href="'/winkel/'+prod.url">{{$t('more_info')}}</a></li>
                                    </ul>
                                </div>
                            </el-radio>
                        </el-radio-group>
                    </div>
                    <div v-else>
                        {{$t('no_products_found')}}..
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue'
    import _ from 'lodash'
    import {mapState} from 'vuex'

    export default {
        name: 'Controller',
        data () {
            return {
                products: [],
                control: '',
                dimmable: '',
                dimmables: [],
                controls: []
            }
        },
        computed:{
            ...mapState('configurator', {
                mainValues: state => state.mainValues
            }),
            ...mapState('controller', {
                controller: state => state.controller
            })
        },
        watch: {
            'mainValues.light_colour':  function (newValue, oldValue) {
                if(newValue !== oldValue)
                    this.getControllers()
            },
            'mainValues.voltage':  function (newValue, oldValue) {
                if(newValue !== oldValue)
                    this.getControllers()
            }
        },
        mounted () {
            this.getControllers()
        },
        methods: {
            getAvailableControllerValues(){
                var self = this
                Vue.http.post(
                    '/led/getControllerFilterValues',
                    {
                        colour: self.mainValues.light_colour,
                        voltage: self.mainValues.voltage
                    },
                    {
                        responseType: 'json'
                    }
                ).then(function(response){
                    if (response.data){
                        self.dimmables = response.data.dimmables
                        self.controls = response.data.controls
                    }
                });
            },
            async getControllers() {
                await this.getAvailableControllerValues()

                var self = this
                Vue.http.post(
                    '/led/get/controllers',
                    {
                        colour: self.mainValues.light_colour,
                        voltage: self.mainValues.voltage,
                        dimmable: self.dimmable ? self.dimmable : false,
                        control: self.control ? self.control : false
                    },
                    {
                        responseType: 'json'
                    }
                ).then(function (response) {
                    if (response.data) {
                        self.products = response.data;

                        if (!_.find(self.products, {id: self.controller.product.id})) {
                            self.controller.product = ''
                        }
                    }
                });
            },
            setProduct(value){
                this.$store.commit('controller/setProduct', value);
            }
        }
    }
</script>
