<template>
    <div>
        <div class="row">
            <div class="col-md-8 col-sm-8 intro">
                <h1 class="title-black">{{$t("led_strip_configurator")}}</h1>
                <p v-if="!summary">
                    {{$t("led_strip_configurator_info")}}
                </p>
            </div>
        </div>

        <div class="row">
            <side-summary v-if="!summary"></side-summary>
            <div v-show="!summary" class="col-md-8 col-sm-12 col-md-pull-4 compose-left">
                <form>
                    <el-collapse accordion :value="pane" @change="changePane">
                        <el-collapse-item :name="1" id="group-parent">
                            <template slot="title">
                                <h4 class="panel-title">
                                    <span class="step-count">1</span>
                                    <span class="step-name">{{$t("project")}} {{group}}</span>
                                </h4>
                            </template>
                            <group :tooltips="tooltips"
                                   :light_colours="light_colours"
                                   :voltages="voltages"
                                   :ip_values="ip_values"
                            ></group>
                        </el-collapse-item>

                        <el-collapse-item id="lightsources-parent" :name="2" class="lightsources-parent">
                            <template slot="title">
                                <h4 class="panel-title">
                                    <span class="step-count">2</span>
                                    <span class="step-name">{{$t("lightsources")}}</span>
                                    <span v-if="getPowerAmountTotal" class="power-amount pull-right">{{getPowerAmountTotal}}W</span>
                                </h4>
                            </template>
                            <lightsources :tooltips="tooltips"></lightsources>
                        </el-collapse-item>
                        <el-collapse-item :name="3" id="controller-parent" class="lightsources-parent">
                            <template slot="title">
                                <h4 class="panel-title">
                                    <span class="step-count">3</span>
                                    <span class="step-name">{{$t("management")}}</span>
                                </h4>
                            </template>
                            <controllers :tooltips="tooltips"></controllers>
                        </el-collapse-item>
                        <el-collapse-item :name="4" id="supply-parent" class="lightsources-parent">
                            <template slot="title">
                                <h4 class="panel-title">
                                    <span class="step-count">4</span>
                                    <span class="step-name">{{$t("supply")}}</span>
                                </h4>
                            </template>
                            <supplies :summary.sync="summary" :tooltips="tooltips"></supplies>
                        </el-collapse-item>
                    </el-collapse>
                </form>
            </div>
            <div class="col-lg-8 col-md-10 col-sm-12 config-summary">
                <span class="back-to-config" v-if="summary" @click="summary = false">
                    <i class="fa fa-angle-left"></i>{{$t("back_to_configurator")}}
                </span>
                <led-summary v-if="summary"></led-summary>
            </div>
        </div>
    </div>
</template>

<script>
    import LedSummary from "./LedSummary.vue";
    import SideSummary from "./SideSummary.vue";
    import Group from "./Steps/Group/Group.vue";
    import Lightsources from "./Steps/Lightsource/Lightsources.vue";
    import Controllers from "./Steps/Controller/Controller.vue";
    import Supplies from "./Steps/Supply/Supplies.vue";
    import _ from 'lodash'
    import {mapState} from 'vuex'

    export default {
        name: 'LedConfigurator',
        components: {LedSummary, SideSummary, Group, Lightsources, Controllers, Supplies},
        props: ["tooltips", "light_colours", "voltages", "ip_values", "language", "translation"],
        data () {
            return {
                summary: false
            }
        },
        computed: {
            ...mapState('language', {
                languageCode: state => state.language_code
            }),
            ...mapState('configurator', {
                mainValues: state => state.mainValues,
                pane: state => state.pane,
                group: state => state.group
            }),
            ...mapState('lightsources', {
                lightsources: state => state.lightsources
            }),
            ...mapState('supplies', {
                supplies: state => state.supplies
            }),
            ...mapState('controller', {
                controller: state => state.controller
            }),
            getPowerAmountTotal () {
                var totalPower = 0

                _.forEach(this.lightsources, function (lightsource){
                    if(lightsource.led_strip.product.power) {
                        totalPower = totalPower+parseFloat(lightsource.led_strip.product.power*lightsource.length)
                    }
                })

                return totalPower ? totalPower : false
            }
        },
        mounted(){
            this.$store.commit('language/setLanguage', this.language)
        },
        methods: {
            changePane(value){
                this.$store.commit('configurator/setLightsourcePane', 0)
                this.$store.commit('lightsources/setLightsourceTab', 'led_strip')
                this.$store.commit('controller/setControllerTab', 'led_controller')
                this.$store.commit('configurator/setPane', value)
            }
        }
    }
</script>