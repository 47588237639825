import Vue from 'vue'

const state = {
    mainValues: {
        light_colour: 'single_colour',
        voltage: '24',
        ip_value: 'ip20'
    },
    pane: 1,
    lightsource_pane: 0,
    group: ''
}

const getters = {}

const actions = {
}

const mutations = {
    setLightsourcePane(state, data){
        Vue.set(state, 'lightsource_pane', data);
    },
    setPane(state, data){
        Vue.set(state, 'pane', data);
    },
    setGroup(state, data){
        Vue.set(state, 'group', data);
    }
}

export default {
    namespaced:true,
    state,
    getters,
    actions,
    mutations
}
