<template>
  <el-dialog
    :visible.sync="visible"
    :before-close="handleClose"
    :close-on-click-modal="true"
    :close-on-press-escape="true"
    :lock-scroll="true"
    :fullscreen="fullscreen"
    class="addresses-modal"
  >
    <!-- title -->
    <span slot="title" class="dialog-header">
      <span class="el-dialog__title">
        {{ $t("delivery_address") }}
      </span>
      <ul class="nav nav-pills text-center">
        <div class="row">
          <div
            class="col-xs-6 col-sm-6 col-md-6 address-type"
            :class="{ active: addressType === 'own_address' }"
          >
            <a @click="addressType = 'own_address'">
              <li role="presentation" id="modal-adressen-nav" class="active">
                <h5>
                  {{ $t("own_addresses") }}
                </h5>
              </li>
            </a>
          </div>
          <div
            class="col-xs-6 col-sm-6 col-md-6 address-type"
            :class="{ active: addressType === 'pick_up' }"
          >
            <a @click="addressType = 'pick_up'">
              <li role="presentation" id="modal-afhalen-nav" class="">
                <h5>
                  {{ $t("pick_up") }}
                </h5>
              </li>
            </a>
          </div>
        </div>
      </ul>
    </span>
    <!-- Content -->
    <!-- switcher pickup and delivery -->

    <!-- select own address -->
    <div class="content">
    <div v-if="addressType === 'own_address'">
      <span :key="index" v-for="(address, index) in addresses">
        <div class="row" @click="setDeliveryAddress(address.Code)">
          <div class="col-xs-2 col-sm-2 col-md-2 text-center modal-icon">
            <i class="fal fa-map-marker-alt fa-3x"></i>
          </div>
          <div class="col-xs-8 col-sm-8 col-md-8">
            <h5 class="bold">
              {{ address.Name }}
            </h5>
            {{ address.Address }}
            <br />
            {{ address.Post_Code }} {{ formatCity(address.City) }}
            <br />
            {{ formatCountry(address.Country_Region_Code) }}<br />
          </div>
          <div class="col-xs-2 col-sm-2 col-md-2 text-center modal-icon">
            <i class="far fa-ellipsis-v fa-2x"></i>
          </div>
          <div
            v-if="activeAddress === address.Code"
            class="col-xs-12 col-sm-12 col-md-12"
          >
            <div class="green-text text-center">
              {{ $t("current_address_selected") }}
            </div>
          </div>
        </div>
        <hr />
      </span>
    </div>
    <!-- select pickup -->
    <div v-if="addressType === 'pick_up'">
      <div class="row" @click="setDeliveryAddress('pick-up')">
        <div class="col-xs-2 col-sm-2 col-md-2 text-center modal-icon">
          <i class="fal fa-map-marker-alt fa-3x"></i>
        </div>
        <div class="col-xs-8 col-sm-8 col-md-8 text-wrapper">
          <h5 class="bold">Nedelko B.V.</h5>
          Riga 10<br />
          2993 LW Barendrecht <br />
          Nederland<br />
        </div>
        <div class="col-xs-2 col-sm-2 col-md-2 text-center modal-icon">
          <i class="far fa-ellipsis-v fa-2x"></i>
        </div>
        <div
          v-if="activeAddress === 'pick-up'"
          class="col-xs-12 col-sm-12 col-md-12"
        >
          <div class="green-text text-center">
            {{ $t("current_address_selected") }}
          </div>
        </div>
      </div>
    </div>
    </div>
    <!-- footer -->
    <span
      slot="footer"
      class="dialog-footer"
      v-if="addressType === 'own_address'"
    >
      <button
        @click="addNewAddressModal"
        type="button"
        class="button-full-width button-green-slide width-100"
      >
        {{ $t("new_delivery_address") }}
      </button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: "AddressesModal",
  props: {
    fullscreen: Boolean,
    visible: Boolean,
    addresses: Array,
    activeAddress: String,
  },
  data() {
    return {
      addressType: "own_address",
    };
  },
  methods: {
    formatCity(city) {
      let city1, city2;
      city1 = city.toLowerCase();
      city2 = city1.charAt(0).toUpperCase() + city1.slice(1);
      return city2;
    },
    formatCountry(country) {
      switch (country) {
        case "NL":
          return "Nederland";
        case "DE":
          return "Duitsland";
        case "EN":
          return "Verenigd Koninkrijk";
        case "BE":
          return "Belgi\xEB";
        case "LU":
          return "Luxemburg";
        case "FR":
          return "Frankrijk";
        default:
          return "Land onbekend";
      }
    },
    addNewAddressModal() {
      this.handleClose();
      this.$emit("openNewAddressModal");
    },
    handleClose() {
      this.$emit("update:visible", false);
    },
    setDeliveryAddress(code) {
      this.$emit("update:activeAddress", code);
    },
  },
};
</script>

<style>
.addresses-modal .el-dialog__header {
  text-align: center;
  border-bottom: 1px solid #e2e2e2;
}
.addresses-modal .el-dialog__body {
  padding-top: 15px;
}
.addresses-modal .nav-pills {
  padding-bottom: 10px;
}
.addresses-modal .address-type.active {
  border-bottom: 2px solid #3fa435;
}
.addresses-modal .address-type {
  border-bottom: 1px solid #e2e2e2;
  padding-bottom: 10px;
}
</style>