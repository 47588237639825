import Vue from "vue";

const state = {
    controller: {
        activeName: "led_controller",
        product: '',
        amount: 0
    },
    amplifiers: [],
    remotes: []
}

const getters = {}

const actions = {
}

const mutations = {
    setControllerTab(state, data){
        Vue.set(state.controller, 'activeName', data);
    },
    setProduct(state, data){
        Vue.set(state.controller, 'product', data)
    },
    setProductAmount(state, amount){
        Vue.set(state.controller, 'amount', amount)
    },
    resetProduct(){
        Vue.set(state.controller, 'product', '')
        Vue.set(state.controller, 'amount', 0)
    },
    removeAmplifier(state, index){
        state.amplifiers.splice(index, 1)
    },
    updateAmplifierAmount(state, {index, amount}){
        Vue.set(state.amplifiers, index, {...state.amplifiers[index], amount})
    },
    addAmplifier(state, product){
        state.amplifiers.push(product)
    }
}

export default {
    namespaced:true,
    state,
    getters,
    actions,
    mutations
}
