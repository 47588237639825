<template>
    <div>
        <div class="col-md-4 col-sm-12 col-md-push-8 sidebar-selection">
            <div class="content">
                <h3 class="panel-title">{{$t("your_selection")}}</h3>
                <el-collapse>
                    <el-collapse-item :title="'1. ' + $t('room')" name="1">
                        <div class="row">
                            <div class="col-xs-6">
                                {{$t("reference")}}:
                            </div>
                            <div class="col-xs-6 text-right">
                                {{group}}
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-xs-6">
                                {{$t("light_colour")}}:
                            </div>
                            <div class="col-xs-6 text-right">
                                <span>
                                    {{mainValues.light_colour == 'single_colour' ? 'Single colour' : mainValues.light_colour }}
                                </span>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-xs-6">
                                {{$t("voltage")}}:
                            </div>
                            <div class="col-xs-6 text-right">
                                <span>
                                    {{mainValues.voltage}}V
                                </span>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-xs-6">
                                {{$t("ip_value")}}:
                            </div>
                            <div class="col-xs-6 text-right">
                                <span class="text-uppercase">
                                    {{mainValues.ip_value}}
                                </span>
                            </div>
                        </div>

                        <span class="edit">
                            <i class="fa fa-wrench" aria-hidden="true"></i>
                            <a href="#">{{$t("change")}}</a>
                        </span>
                    </el-collapse-item>
                    <el-collapse-item v-if="lightsourcesSummarized.length > 0"
                                      v-for="(lightsource, index) in lightsourcesSummarized" :title="index+2+'. ' + $t('lightsource') + ' ' + lightsource.reference"
                                      :key="index"
                                      :name="1+index">
                        <div class="row">
                            <div class="col-xs-8">
                                {{$t("strip")}} ({{$t("length")}}: {{lightsource.led_strip.length}}m)
                            </div>
                            <div class="col-xs-4 text-right">
                                {{$t("amount")}}
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xs-10">
                                <span>
                                    {{lightsource.led_strip.product}}
                                </span>
                            </div>
                            <div class="col-xs-2 text-right">
                                <span>
                                    {{lightsource.led_strip.amount}}
                                </span>
                            </div>
                        </div>

                        <hr/>

                        <div class="row">
                            <div class="col-xs-6">
                                {{$t("profile")}}
                            </div>
                            <div class="col-xs-6 text-right">
                                {{$t("amount")}}
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xs-10">
                                <span>
                                    {{lightsource.led_profile.product}}
                                </span>
                            </div>
                            <div class="col-xs-2 text-right">
                                <span>
                                    {{lightsource.led_profile.amount}}
                                </span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xs-3">
                                {{$t("lens")}}
                            </div>
                            <div class="col-xs-9 text-right">
                                <span>
                                    {{lightsource.led_profile.lens}}
                                </span>
                            </div>
                        </div>

                        <hr v-if="lightsource.accessories.length > 0"/>

                        <div class="row"  v-if="lightsource.accessories.length > 0">
                            <div class="col-xs-6">
                                {{$t("accessories")}}
                            </div>
                            <div class="col-xs-6 text-right">
                                {{$t("amount")}}
                            </div>
                        </div>
                        <div  v-if="lightsource.accessories.length > 0" class="row" :key="index" v-for="(accessory,index) in lightsource.accessories">
                            <div class="col-xs-10">
                                <span>
                                    {{accessory.product}}
                                </span>
                            </div>
                            <div class="col-xs-2 text-right">
                                <span>
                                    {{accessory.amount}}
                                </span>
                            </div>
                        </div>

                        <span class="edit">
                            <i class="fa fa-wrench" aria-hidden="true"></i>
                            <a href="">{{$t("change")}}</a>
                        </span>
                    </el-collapse-item>
                    <el-collapse-item :title="lightsourcesSummarized.length+2+'. ' + $t('management')" :name="lightsourcesSummarized.length+2">
                        <div class="row">
                            <div class="col-xs-12">
                                {{$t("controller")}}
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xs-10">
                                <span>
                                    {{controllerSummarized.controller.product_name}}
                                </span>
                            </div>
                        </div>

                        <hr v-if="controllerSummarized.amplifiers.length > 0"/>

                        <div class="row" v-if="controllerSummarized.amplifiers.length > 0">
                            <div class="col-xs-8">
                                {{$t("amplifiers")}}
                            </div>
                            <div class="col-xs-4 text-right">
                                {{$t("amount")}}
                            </div>
                        </div>
                        <div v-if="controllerSummarized.amplifiers.length > 0" class="row" :key="index" v-for="(amplifier,index) in controllerSummarized.amplifiers">
                            <div class="col-xs-10">
                                <span>
                                    {{amplifier.product_name}}
                                </span>
                            </div>
                            <div class="col-xs-2">
                                <span>
                                    {{amplifier.amount}}
                                </span>
                            </div>
                        </div>

                        <hr v-if="controllerSummarized.remotes.length > 0" />

                        <div class="row" v-if="controllerSummarized.remotes.length > 0">
                            <div class="col-xs-8">
                                {{$t("remotes")}}
                            </div>
                            <div class="col-xs-4 text-right">
                                {{$t("amount")}}
                            </div>
                        </div>
                        <div class="row" v-if="controllerSummarized.remotes.length > 0" :key="index" v-for="(remote,index) in controllerSummarized.remotes">
                            <div class="col-xs-10">
                                <span>
                                    {{remote.product_name}}
                                </span>
                            </div>
                            <div class="col-xs-2">
                                <span>
                                    {{remote.amount}}
                                </span>
                            </div>
                        </div>
                    </el-collapse-item>
                    <el-collapse-item  :title="lightsourcesSummarized.length+3+'. ' + $t('supply')" :name="lightsourcesSummarized.length+3">
                        <div class="row" v-if="supplies.length > 0">
                            <div class="col-xs-8">
                                {{$t("amplifiers")}}
                            </div>
                            <div class="col-xs-4 text-right">
                                {{$t("amount")}}
                            </div>
                        </div>
                        <div class="row" v-if="supplies.length > 0" :key="index" v-for="(supply, index) in supplies">
                            <div class="col-xs-10">
                                <span>
                                    {{supply.product_name}}
                                </span>
                            </div>
                            <div class="col-xs-2">
                                <span>
                                    {{supply.amount}}
                                </span>
                            </div>
                        </div>

                    </el-collapse-item>
                </el-collapse>
                <div class="col-xs-16">
                    <el-button @click="reload()">
                        <i class="fa fa-refresh" aria-hidden="true"></i> {{$t("delete_current_configuration")}}
                    </el-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex'
    import _ from 'lodash'

    export default {
        name: 'SideSummary',
        data () {
            return {
            }
        },
        computed:{
            ...mapState('language', {
                languageCode: state => state.language_code
            }),
            ...mapState('lightsources', {
                lightsources: state => state.lightsources
            }),
            ...mapState('configurator', {
                mainValues: state => state.mainValues,
                group: state => state.group
            }),
            ...mapState('controller', {
                controller: state => state.controller,
                amplifiers: state => state.amplifiers,
                remotes: state => state.remotes
            }),
            ...mapState('supplies', {
                supplies: state => state.supplies
            }),
            lightsourcesSummarized: function () {
                let self = this;
                return _.map(this.lightsources, function(lightsource, index) {
                    return {
                        reference: index+1 + (lightsource.reference ? ' (' + lightsource.reference + ')' : ''),
                        led_strip: {
                            "product": lightsource.led_strip.product.product_name ? lightsource.led_strip.product.product_name : self.$t('no_chosen_strip'),
                            "amount": lightsource.led_strip.amount,
                            "length": lightsource.length
                        },
                        led_profile: {
                            "product": lightsource.led_profile.product.product_name ? lightsource.led_profile.product.product_name : self.$t('no_chosen_profile'),
                            "amount": lightsource.led_profile.amount,
                            "lens": lightsource.led_profile.lens.product_name ? lightsource.led_profile.lens.product_name : self.$t('no_chosen_lens')
                        },
                        accessories: _.map(lightsource.accessories, function(accessory){
                            return {
                                "product": accessory.product_name,
                                "amount": accessory.amount
                            }
                        })
                    }
                })
            },
            controllerSummarized: function () {
                return {
                    controller: {
                        product_id: this.controller.product.product_id,
                        product_name: this.controller.product.product_name ? this.controller.product.product_name : this.$t('no_chosen_controller')
                    },
                    amplifiers: this.amplifiers,
                    remotes: this.remotes
                }
            }
        },
        mounted () {
        },
        methods: {
            reload(){
                location.reload();
            }
        }
    }
</script>