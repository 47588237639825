import { render, staticRenderFns } from "./LedStripTab.vue?vue&type=template&id=5248bf88&"
import script from "./LedStripTab.vue?vue&type=script&lang=js&"
export * from "./LedStripTab.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "LedStripTab.vue"
export default component.exports