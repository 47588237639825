<template>
    <div>
        <el-form>
            <div class="row">
                <div class="col-md-3">
                    {{$t('filter_ledstrips')}} :
                </div>

                <div class="col-md-3">
                    <el-form-item :label="$t('ledstrip_power')">
                        <el-select clearable @change="getStrips" v-model="power" :popper-append-to-body="false" placeholder="7,2 W/m">
                            <el-option
                                    v-for="(power, index) in powers"
                                    :key="index"
                                    :label="index+'W'"
                                    :value="index">
                                {{index}}W
                            </el-option>
                        </el-select>
                    </el-form-item>
                </div>
                <div class="col-md-3">
                    <el-form-item v-if="mainValues.light_colour === 'single_colour'" :label="$t('colour_choice')">
                        <el-select clearable @change="getStrips" v-model="colour_choice" :popper-append-to-body="false" :placeholder="$t('colour_choice')">
                            <el-option
                                    v-for="(colour, index) in colour_choices"
                                    :key="index"
                                    :label="colour"
                                    :value="index">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <p v-else-if="mainValues.light_colour === 'rgb'">RGB</p>
                    <p v-else>RGBW</p>
                </div>
            </div>
            <hr class="filter-separator" />
        </el-form>
        <div class="row">
            <div class="input-group">
                <div class="col-lg-12">
                    <div class="radio-image row-4" v-if="products.length > 0">
                        <el-radio-group v-model="product">
                            <el-radio :label="prod" v-for="(prod, prod_index) in products" :key="prod_index" >
                                <div class="bg-image"
                                     :style="prod.image ? 'background-image: url(' + prod.image + ')' : 'background-image: url(/images/afbeelding-niet-gevonden.jpg)'"
                                ></div>
                                <div class="block-info">
                                    <ul>
                                        <li>{{prod.product_name}}</li>
                                        <li>{{prod.power}} W</li>
                                        <li>{{prod.voltage}} V</li>
                                        <li>{{prod.serie}} serie</li>
                                        <li class="link"><a target="_blank" :href="'/winkel/'+prod.url">{{$t('more_info')}}</a></li>
                                    </ul>
                                </div>
                            </el-radio>
                        </el-radio-group>
                    </div>
                    <div v-else>
                        {{$t('no_products_found')}}..
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue'
    import _ from 'lodash'
    import {mapState} from 'vuex'

    export default {
        name: 'LedStrip',
        props: ['lightsource_index', 'is_open'],
        data () {
            return {
                products: [],
                power: '',
                colour_choice: '',
                powers: [],
                colour_choices: []
            }
        },
        computed: {
            ...mapState('configurator', {
                mainValues: state => state.mainValues
            }),
            product: {
                get () { return this.$store.state.lightsources.lightsources[this.lightsource_index].led_strip.product },
                set (value) {
                    this.setProduct(value)
                }
            }
        },
        watch: {
            'is_open':  {
                handler: function (isOpen) {
                    if(isOpen)
                        this.getStrips()
                },
                immediate: true
            }
        },
        methods: {
            getAvailableLightsourceValues(){
                var self = this
                Vue.http.post(
                    '/led/getLightsourceStripFilterValues',
                    {
                        light_colour: self.mainValues.light_colour,
                        voltage: self.mainValues.voltage,
                        IP_value: self.mainValues.ip_value,
                        power: self.power ? self.power : false,
                        colour: self.colour_choice ? self.colour_choice : false
                    },
                    {
                        responseType: 'json'
                    }
                ).then(function(response){
                    if (response.data){
                        self.powers = response.data.powers;
                        self.colour_choices = response.data.colours
                    }
                });
            },
            async getStrips() {
                await this.getAvailableLightsourceValues();

                var self = this
                Vue.http.post(
                    '/led/get/strips',
                    {
                        light_colour: self.mainValues.light_colour,
                        voltage: self.mainValues.voltage,
                        IP_value: self.mainValues.ip_value,
                        power: self.power ? self.power : false,
                        colour: self.colour_choice ? self.colour_choice : false
                    },
                    {
                        responseType: 'json'
                    }
                ).then(function (response) {
                    if (response.data) {
                        self.products = response.data;

                        if (self.product.id && !_.find(self.products, {id: self.product.id})) {
                            self.setProduct(false)
                        }
                    }
                });
            },
            setProduct(value){
                this.$store.commit('lightsources/setLedstrip', {
                    index: this.lightsource_index,
                    product: value
                })
            }
        }
    }
</script>
