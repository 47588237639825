import Vue from "vue";

// Import ElementUI from libs/element where partial loading are defined
import "./libs/element/index";
import "element-ui/lib/theme-chalk/index.css";
// import locale from 'element-ui/lib/locale/lang/en'

import "@fortawesome/fontawesome-pro/scss/regular.scss";
import "@fortawesome/fontawesome-pro/scss/light.scss";
import "@fortawesome/fontawesome-pro/scss/solid.scss";
import "@fortawesome/fontawesome-pro/scss/brands.scss";
import "./libs/font-awesome/index";

import "./sass/app.scss";
import "./sass/form_element_overwrite.scss";

import store from "./store";
import LedConfigurator from "./components/LedConfigurator.vue";

import Checkout from "./components/Checkout/Checkout.vue";
import CheckoutDetails from "./components/Checkout/CheckoutDetails.vue";
import Thanks from "./components/Checkout/Thanks.vue";

import EditUser from "./components/Account/EditUser.vue";
import EditCompany from "./components/Account/EditCompany.vue";

import ProductInfo from "./components/Product/ProductInfo.vue";

import Orders from "./components/Account/Orders.vue";
import OrderInfo from "./components/Account/OrderInfo.vue";

import Invoices from "./components/Account/Invoices.vue";

import HeaderCart from "./components/Checkout/HeaderCart.vue";

import ChangeLanguage from "./components/Header/ChangeLanguage.vue";
import MobNav from "./components/Header/MobNav.vue";
import MobSearch from "./components/Header/MobSearch.vue";

import HubSpotForm from "./components/HubSpotForm.vue";

import "./sass/element-variables.scss";

import VueI18n from "vue-i18n";

import VTooltip from "v-tooltip";
import "./sass/vue-tooltip.scss";

Vue.use(VTooltip);
Vue.use(VueI18n);

if (document.getElementById("mob-nav") && window.innerWidth < 768) {
  let translation = $("#mob-nav").data().translation;

  const i18n = new VueI18n({
    locale: "default",
    messages: translation,
  });

  new Vue({
    store,
    el: "#mob-nav",
    i18n,
    render(h) {
      return h(MobNav, { props: $("#mob-nav").data() });
    },
  });
}

if (document.getElementById("mob-search") && window.innerWidth < 768) {
  let translation = $("#mob-search").data().translation;

  const i18n = new VueI18n({
    locale: "default",
    messages: translation,
  });

  new Vue({
    store,
    el: "#mob-search",
    i18n,
    render(h) {
      return h(MobSearch, { props: $("#mob-search").data() });
    },
  });
}

if (document.getElementById("led_configurator")) {
  let translation = $("#led_configurator").data().translation;

  const i18n = new VueI18n({
    locale: "default",
    messages: translation,
  });

  new Vue({
    store,
    el: "#led_configurator",
    i18n,
    render(h) {
      return h(LedConfigurator, { props: $("#led_configurator").data() });
    },
  });
}

if (document.getElementById("user_component")) {
  let translation = $("#user_component").data().translation;
  const i18n = new VueI18n({
    locale: "default",
    messages: translation,
  });

  new Vue({
    store,
    el: "#user_component",
    i18n,
    render(h) {
      return h(EditUser, { props: $("#user_component").data() });
    },
  });
}

if (document.getElementById("company_component")) {
  let translation = $("#company_component").data().translation;
  const i18n = new VueI18n({
    locale: "default",
    messages: translation,
  });

  new Vue({
    store,
    el: "#company_component",
    i18n,
    render(h) {
      return h(EditCompany, { props: $("#company_component").data() });
    },
  });
}

if (document.getElementById("checkout")) {
  let translation = $("#checkout").data().translation;

  const i18n = new VueI18n({
    locale: "default",
    messages: translation,
  });

  new Vue({
    store,
    el: "#checkout",
    i18n,
    render(h) {
      return h(Checkout, { props: $("#checkout").data() });
    },
  });
}

if (document.getElementById("CheckoutDetails")) {
  let translation = $("#CheckoutDetails").data().translation;

  const i18n = new VueI18n({
    locale: "default",
    messages: translation,
  });

  new Vue({
    store,
    el: "#CheckoutDetails",
    i18n,
    render(h) {
      return h(CheckoutDetails, { props: $("#CheckoutDetails").data() });
    },
  });
}

if (document.getElementById("checkout_thanks")) {
  let translation = $("#checkout_thanks").data().translation;

  const i18n = new VueI18n({
    locale: "default",
    messages: translation,
  });

  new Vue({
    store,
    el: "#checkout_thanks",
    i18n,
    render(h) {
      return h(Thanks, { props: $("#checkout_thanks").data() });
    },
  });
}

if (document.getElementById("product-info-component")) {
  let translation = $("#product-info-component").data().translation;

  const i18n = new VueI18n({
    locale: "default",
    messages: translation,
  });

  new Vue({
    store,
    el: "#product-info-component",
    i18n,
    render(h) {
      return h(ProductInfo, { props: $("#product-info-component").data() });
    },
  });
}

if (document.getElementById("client-orders")) {
  let translation = $("#client-orders").data().translation;

  const i18n = new VueI18n({
    locale: "default",
    messages: translation,
  });

  new Vue({
    store,
    el: "#client-orders",
    i18n,
    render(h) {
      return h(Orders, { props: $("#client-orders").data() });
    },
  });
}

if (document.getElementById("order-info")) {
  let translation = $("#order-info").data().translation;

  const i18n = new VueI18n({
    locale: "default",
    messages: translation,
  });

  new Vue({
    store,
    el: "#order-info",
    i18n,
    render(h) {
      return h(OrderInfo, { props: $("#order-info").data() });
    },
  });
}

if (document.getElementById("client-invoices")) {
  let translation = $("#client-invoices").data().translation;

  const i18n = new VueI18n({
    locale: "default",
    messages: translation,
  });

  new Vue({
    store,
    el: "#client-invoices",
    i18n,
    render(h) {
      return h(Invoices, { props: $("#client-invoices").data() });
    },
  });
}

if (document.getElementById("header-cart")) {
  let translation = $("#header-cart").data().translation;

  const i18n = new VueI18n({
    locale: "default",
    messages: translation,
  });

  new Vue({
    store,
    el: "#header-cart",
    i18n,
    render(h) {
      return h(HeaderCart, { props: $("#header-cart").data() });
    },
  });
}

if (document.getElementById("change-language")) {
  let translation = $("#change-language").data().translation;
  const i18n = new VueI18n({
    locale: "default",
    messages: translation,
  });

  new Vue({
    store,
    el: "#change-language",
    i18n,
    render(h) {
      return h(ChangeLanguage, { props: $("#change-language").data() });
    },
  });
}

if (document.getElementById("hub-spot-form")) {
  new Vue({
    store,
    el: "#hub-spot-form",
    render(h) {
      return h(HubSpotForm, { props: $("#hub-spot-form").data() });
    },
  });
}