<template>
    <div class="controller-wrapper">
        <div class="row">
            <div class="col-lg-12">
                <el-tabs v-model="controller.activeName" :stretch="true" :before-leave="toTop">
                    <el-tab-pane :label="$t('controller_choice')" name="led_controller">
                        <controller-tab
                                :tooltips="tooltips"
                                :tableData="powerTableData"
                                :strip_rolls="strip_rolls"
                                :used_rolls="used_rolls"
                                :controller_alert_text="controller_alert_text"></controller-tab>
                    </el-tab-pane>
                    <el-tab-pane :label="$t('amplifier_choice')" name="led_amplifier" :disabled="!controller.product.id">
                        <amplifier-tab :tooltips="tooltips"
                                       :tableData="powerTableData"
                                       :strip_rolls="strip_rolls"
                                       :used_rolls="used_rolls"
                                       v-if="controller.product.id">
                        </amplifier-tab>
                    </el-tab-pane>
                    <el-tab-pane :label="$t('remote_choice')" name="remote_control" :disabled="!controller.product.id">
                        <remote-control-tab :tooltips="tooltips" v-if="controller.product.id"></remote-control-tab>
                    </el-tab-pane>
                </el-tabs>
            </div>

            <div class="row">
                <div class="col-xs-12">
                    <hr class="footer-separator" />
                </div>

                <div v-if="controller.activeName === 'led_controller'" class="col-sm-6 text-center col-xs-12">
                    <el-button type="info" plain class="pull-left col-xs-12"
                               @click="nextCollapsePane(2)">
                        <span><i class="fa fa-chevron-left"></i> {{$t('lightsources')}}</span>
                    </el-button>
                </div>
                <div class="col-sm-6 col-xs-12" v-else-if="controller.activeName !== 'led_controller'">
                    <el-button type="info" plain class="xs-margintop-15 pull-left col-xs-12"
                               @click="nextTabPane(controller.activeName === 'led_amplifier' ? 'led_controller' : 'led_amplifier')">
                        <span><i class="fa fa-chevron-left"></i> {{controller.activeName === 'led_amplifier' ? $t('controller') : $t('amplifiers')}}</span>
                    </el-button>
                </div>
                <div class="col-sm-6 col-xs-12" v-if="controller.activeName !== 'remote_control'">
                    <el-button type="primary"
                               class="pull-right col-xs-12 xs-margintop-15"
                               @click="nextTabPane(controller.activeName === 'led_controller' ? 'led_amplifier' : 'remote_control')">
                        <span>{{controller.activeName === 'led_controller' ? $t('amplifiers') : $t('remotes')}} <i class="fa fa-chevron-right"></i></span>
                    </el-button>
                </div>

                <div v-if="controller.activeName === 'remote_control'" class="col-sm-6 text-center col-xs-12">
                    <el-button @click="nextCollapsePane(4)" type="primary" class="pull-right col-xs-12 xs-margintop-15">
                        <span>{{$t('supply')}} <i class="fa fa-chevron-right"></i></span>
                    </el-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue'
    import ControllerTab from './Tabs/ControllerTab.vue'
    import AmplifierTab from './Tabs/AmplifierTab.vue'
    import RemoteControlTab from './Tabs/RemoteControlTab.vue'
    import {mapState} from 'vuex'
    import _ from 'lodash'

    export default {
        name: 'Controller',
        props: ["tooltips"],
        components: {ControllerTab, AmplifierTab, RemoteControlTab},
        data () {
            return {
                controller_alert_text: '',
                used_rolls: [],
                controller_channels: [],
                amplifier_channels: []
            }
        },
        computed:{
            ...mapState('language', {
                languageCode: state => state.language_code
            }),
            ...mapState('configurator', {
                voltage: state => state.mainValues.voltage,
                light_colour: state => state.mainValues.light_colour
            }),
            ...mapState('controller', {
                controller: state => state.controller,
                amplifiers: state => state.amplifiers
            }),
            ...mapState('lightsources', {
                lightsources: state => _.map(state.lightsources, (lightsource) => _.pick(lightsource, ['length', 'led_strip', 'reference']))
            }),
            strip_rolls () {
                let self = this;
                let strip_rolls = [];

                let roll_index = 0;

                _.forEach(this.lightsources, function (lightsource, lightsource_index) {
                    if(!lightsource.led_strip.valid)
                        return;

                    let length = Number(lightsource.length);

                    let roll_label_index = 1;
                    while (length > 0) {
                        let added_length = 5;
                        if (length > 5) {
                            length = length - added_length;
                        } else {
                            added_length = length
                            length = 0
                        }

                        const lightsource_label = self.$t('lightsource') + ' ' + (lightsource_index + 1) + '.' + (roll_label_index) + (lightsource.reference ? ': (' + lightsource.reference + ')' : '');

                        strip_rolls.push({
                            lightsource_label,
                            label: lightsource_label + " (" + added_length + "m)",
                            id: lightsource.led_strip.product.id + '' + roll_index + '' + added_length,
                            wattage: parseFloat((added_length * lightsource.led_strip.product.power).toFixed(2))
                        })

                        roll_index++;
                        roll_label_index++;
                    }
                })
                return strip_rolls
            },
            powerTableData () {
                return [
                    ...this.controller_channels,
                    ...this.amplifier_channels
                ]
            }
        },
        watch:{
            lightsources () {
                this.powerTable()
            },
            'controller.product.product_id': function(newValue, oldValue) {
                if(newValue !== oldValue){
                    this.controller_channels = []
                    this.updateControllers(this.controller.amount)
                    this.powerTable()
                }
            },
            'controller.amount': function(newValue){
                this.updateControllers(newValue)
                this.powerTable()
            },
            amplifiers () {
                this.updateAmplifiers()
                this.powerTable()
            }
        },
        methods: {
            powerTable(){
                let self = this;
                let totalWattages = [];

                this.used_rolls = [];
                this.setUsedRolls(this.controller_channels)
                this.setUsedRolls(this.amplifier_channels)
                this.fillChannels(this.controller_channels)
                this.fillChannels(this.amplifier_channels)

                if(this.powerTableData.length > 0) {
                    totalWattages = _.map(
                        _.groupBy(this.powerTableData, 'controller'),
                        (controller, name) => {
                            return {
                                name,
                                id: controller[0].controller_id+''+controller[0].product_id,
                                wattage: _.sumBy(controller, 'used_wattage')
                            }
                        }
                    );
                }

                self.$store.commit('supplies/setTotalWattage', totalWattages);
            },
            updateControllers(newAmount) {
                //controller(s) toegevoegd
                const oldAmount = _.uniqBy(this.controller_channels, 'controller_id').length;

                let channels = this.controller.product.channels;
                let max_current = this.controller.product.max_current;

                switch(this.light_colour) {
                    case "rgb":
                        max_current = max_current * channels;
                        channels = 1;
                        break;
                    case "rgbw" :
                        max_current = max_current * channels / 2;
                        channels = 1;
                        break;
                }

                if(newAmount > oldAmount) {
                    for(let i = 0; i < newAmount - oldAmount; i++) {
                        for (let channel_index = 1; channel_index <= channels; channel_index++) {
                            this.controller_channels.push(
                                this.generateRow(
                                    oldAmount + i,
                                    channel_index,
                                    max_current,
                                    this.controller.product.product_name,
                                    this.controller.product.product_id,
                                    channels,
                                    'controller'
                                )
                            )
                        }
                    }
                }
                else if (newAmount === 0) {
                    this.controller_channels.splice(0, this.controller_channels.length);
                }
                //controller(s) verwijderd
                else if (newAmount < oldAmount) {
                    const toRemove = (oldAmount - newAmount) * channels;
                    this.controller_channels.splice(this.controller_channels.length - toRemove, toRemove)
                }
            },
            updateAmplifiers() {
                let newAmplifiers = _.groupBy(this.amplifier_channels, 'product_id');

                let amplifiersByProductId = _.keyBy(this.amplifiers, 'product_id')
                let newAmounts = _.mapValues(amplifiersByProductId, 'amount')

                const oldAmounts = _.mapValues(newAmplifiers, (channels) => {return channels.length  / channels[0].no_of_channels})
                const allProducts = _.union(_.keys(oldAmounts), _.keys(newAmounts))

                for(let index in allProducts) {

                    const product_id = allProducts[index];
                    const product = amplifiersByProductId[product_id];

                    const newAmount = (newAmounts[product_id] ? newAmounts[product_id] : 0);
                    const oldAmount = (oldAmounts[product_id] ? oldAmounts[product_id] : 0);

                    if(!newAmplifiers[product_id])
                        newAmplifiers[product_id] = [];

                    let channels = product ? product.channels : 0;
                    let max_current = product ? product.max_current : 0;

                    switch(this.light_colour) {
                        case "rgb":
                            max_current = max_current * channels;
                            channels = 1;
                            break;
                        case "rgbw" :
                            max_current = max_current * channels / 2;
                            channels = 1;
                            break;
                    }

                    if(newAmount > oldAmount) {
                        for(let i = 0; i < newAmount - oldAmount; i++) {
                            for (let channel_index = 1; channel_index <= channels; channel_index++) {
                                newAmplifiers[product_id].push(
                                    this.generateRow(
                                        oldAmount + i,
                                        channel_index,
                                        max_current,
                                        product.product_name,
                                        product.product_id,
                                        channels,
                                        'amplifier'
                                    )
                                )
                            }
                        }
                    }
                    else if (newAmount === 0) {
                        newAmplifiers[product_id].splice(0,  newAmplifiers[product_id].length);
                    }
                    //controller(s) verwijderd
                    else if (newAmount < oldAmount) {
                        const toRemove = (oldAmount - newAmount) * channels;
                        newAmplifiers[product_id].splice(newAmplifiers[product_id].length - toRemove, toRemove)
                    }
                }

                Vue.set(this, 'amplifier_channels', _.flatten(_.map(newAmplifiers)))
            },
            generateRow(controller_index, channel_index, max_current, product_name, product_id, channels, type){
                let max_wattage = max_current * this.voltage;

                return {
                    controller_id: controller_index,
                    channel: channel_index,
                    controller: product_name + '(' +  parseInt(controller_index+1) + ')',
                    product_id,
                    max_wattage,
                    no_of_channels: channels,
                    lightsources: [], //map from used_lightsources -> reference
                    used_wattage: 0, //sum from used_lightsources -> wattage
                    type
                }
            },
            setUsedRolls(channels) {
                const self = this
                //nog bestaande strip_rolls in used_rolls zetten andere verwijderen
                channels = _.map(channels, (channel) => {
                    channel.used_wattage = 0
                    //al geselecteerde rollen in used_rolls zetten als ze nog bestaan
                    channel.lightsources = _.filter(channel.lightsources, (selected_roll) => {
                        if(_.find(self.strip_rolls, {id: selected_roll.id})) {
                            channel.used_wattage += selected_roll.wattage
                            self.used_rolls.push(selected_roll.id);
                            return true
                        }

                        return false;
                    })

                    return channel
                })
            },
            fillChannels(channels){
                const self = this

                channels = _.map(this.powerTableData, (channel) => {
                    _.forEach(self.strip_rolls, function(strip_roll){
                        if(_.indexOf(self.used_rolls, strip_roll.id) <= -1 && (channel.used_wattage + strip_roll.wattage) <= channel.max_wattage*0.8){
                            channel.used_wattage += strip_roll.wattage
                            self.used_rolls.push(strip_roll.id)
                            channel.lightsources.push(strip_roll)
                        }
                    })
                    return channel
                })
            },
            nextTabPane(value) {
                if(this.controller.product.id) {
                    this.controller.activeName = value;
                } else {
                    this.controller_alert_text = this.$t('select_a_controller_before_amplifier')
                }
            },
            nextCollapsePane(value) {
                this.$store.commit('configurator/setPane', value)

                if(value === 4) {
                    setTimeout(function () {
                        $('html, body').animate({
                            scrollTop: $("#supply-parent").offset().top - 83
                        }, 500);
                    }, 301)
                } else if (value === 2) {
                    setTimeout(function () {
                        $('html, body').animate({
                            scrollTop: $("#lightsources-parent").offset().top - 83
                        }, 500);
                    }, 301)
                }
            },
            toTop(newName, oldName){
                if($(this.$el).offset())
                    $('html, body').animate({
                        scrollTop: $(this.$el).offset().top-162
                    }, 0);
            }
        }
    }
</script>
