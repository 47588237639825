<template>
    <el-form ref="led_strip" label-position="top" :model="lightsource" :rules="rules" v-if="lightsource.led_strip">

        <div class="input-group">
            <div class="row">
                <div class="col-lg-8 col-md-12">
                    <el-form-item :label="$t('reference_lightsource')" v-tooltip.top-start="tooltips.lightsource_reference">
                        <el-input :placeholder="$t('reference_lightsource_example')" v-model="lightsource.reference"></el-input>
                    </el-form-item>
                </div>
            </div>
        </div>


        <div class="input-group">
            <el-form-item :label="$t('strip_total_length')" required="" prop="length" v-tooltip.top-start="tooltips.strip_length">
                <div class="row">
                    <el-input class="col-lg-6 col-md-6 col-sm-6" type="number" v-model="lightsource.length" :min="1" :max="20" @change="formatZero">
                        <template slot="append">{{$t('meter')}}</template>
                    </el-input>
                </div>
            </el-form-item>
        </div>

        <div class="input-group" v-show="!lightsource.led_strip.valid">
            <div class="row">
                <div class="col-lg-5 col-md-5">
                    <el-form-item :label="$t('led_strip_choice')" prop="strip_product" required="" v-if="!lightsource.led_strip.product.id" v-tooltip.top-start="tooltips.strip_choice">
                        <el-button type="primary" plain class="white-background" @click="chooseLedstrip = true">{{$t('choose_a_led_strip')}}</el-button>
                    </el-form-item>
                </div>
            </div>
        </div>

        <div class="input-group" v-if="lightsource.led_strip.valid">
            <div class="row">
                <div class="col-sm-12">
                    <el-form-item :label="$t('for_this_configuration_we_advice') + ' ' + Math.ceil(lightsource.length / 5) + ' LED ' + (Math.ceil(lightsource.length / 5) > 1 ? $t('rolls') : $t('roll')) + ' ' + $t('of_five_meters')"></el-form-item>
                </div>
            </div>
        </div>

        <div class="selected-product input-group" v-show="lightsource.led_strip.valid">
            <div class="row">
                <div class="col-xs-12">
                    <div class="row">
                        <div class="col-sm-5">
                            <img class="selected-product-image"
                                 :src="lightsource.led_strip.product.image ? lightsource.led_strip.product.image : '/images/afbeelding-niet-gevonden.jpg'" />
                        </div>
                        <div class="col-sm-7">
                            <div class="block-info">
                                <ul>
                                    <li>{{lightsource.led_strip.product.product_name}}</li>
                                    <li>{{lightsource.led_strip.product.power}} W</li>
                                    <li>{{lightsource.led_strip.product.voltage}} V</li>
                                    <li>{{lightsource.led_strip.product.serie}} serie</li>
                                    <li class="link"><a target="_blank" :href="'/winkel/' + lightsource.led_strip.product.url">{{$t('more_info')}}</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row" v-show="lightsource.led_strip.valid">
            <div class="col-sm-4 col-xs-12">
                <el-form-item class="input-group product-amount">
                    <el-input-number v-model="lightsource.led_strip.amount" :min="1"></el-input-number>
                </el-form-item>
            </div>
            <div class="col-sm-4 col-xs-12">
                <el-button type="info" plain class="pull-right"
                           @click="chooseLedstrip = true">
                    <span>{{$t('change_led_strip')}}</span>
                </el-button>
            </div>
            <div class="col-sm-4 col-xs-12">
                <el-button type="danger" class="pull-right"
                           @click="resetLedStrip">
                    <span>{{$t('remove_led_strip')}}</span>
                </el-button>
            </div>
        </div>

        <div class="input-group">
            <el-dialog :visible.sync="chooseLedstrip" width="80%" :lock-scroll="true"
                       custom-class="lightsource-dialog"
                       :fullscreen="!!checkFullscreen">
                <led-strip :lightsource_index="lightsource_index" :is_open="chooseLedstrip"></led-strip>
                <span slot="footer" :class="'dialog-footer'+lightsource_index">
                    <hr class="dialog-footer-separator" />
                    <el-button type="info" plain @click="chooseLedstrip = false" class="white-background">{{$t('close')}}</el-button>
                    <el-button type="primary" plain @click="chooseLedstrip = false" class="white-background">{{$t('choose_this_led_strip')}}</el-button>
                </span>
            </el-dialog>
        </div>

        <hr class="footer-separator" />

        <div class="row">
            <div class="col-sm-6 hidden-xs"></div>
            <div class="col-sm-6 col-xs-12">
                <el-button type="primary" class="pull-right white-background col-xs-12"
                           @click="nextTabPane('led_profile')">
                    <span>{{$t('choose_led_profile')}} <i class="fa fa-chevron-right"></i></span>
                </el-button>
            </div>
        </div>

    </el-form>
</template>

<script>
    import LedStrip from '../Popups/LedStrip.vue'
    import {mapState} from 'vuex'

    export default {
        name: 'LedStripTab',
        props: ["tooltips", "lightsource_index"],
        components:{LedStrip},
        data () {
            return {
                rules: {
                    strip_product:  [
                        {required: true, message: this.$t('please_select_a_ledstrip'), trigger: 'blur'}
                    ],
                    length:  [
                        {required: true, message: this.$t('please_fill_a_length'), trigger: 'blur'}
                    ]
                },
                chooseLedstrip: false,
                lightsource: {
                    length: 0
                }
            }
        },
        computed: {
            ...mapState('configurator', {
                light_colour: state => state.mainValues.light_colour
            }),
            ...mapState('language', {
                languageCode: state => state.language_code
            }),
            ...mapState('lightsources', {
                lightsources: state => state.lightsources
            }),
            checkFullscreen(){
                return window.outerWidth <= 767;
            }
        },
        watch: {
            'lightsource.length': function(newValue, oldValue){
                if(newValue !== oldValue) {
                    this.lightsource.led_strip.amount = Math.ceil(newValue / 5);
                    this.lightsource.led_profile.amount = Math.ceil(newValue / 2)
                }
            },
            light_colour () {
                this.resetLedStrip()
            }
        },
        mounted (){
            this.lightsource = this.lightsources[this.lightsource_index]
        },
        methods: {
            formatZero () {
                if(this.lightsource.length.indexOf(0) === 0 && this.lightsource.length.indexOf('.') !== 1) {
                    this.lightsource.length = this.lightsource.length.replace(/^0+/, '')
                }
            },
            nextTabPane(value) {
                let self = this
                this.$refs['led_strip'].validate((valid) => {
                    if (valid) {
                        self.lightsource.activeName = value;
                    } else {
                        return false;
                    }
                });
            },
            resetLedStrip(){
                this.lightsource.led_strip.valid = false;
                this.lightsource.led_strip.product = '';
            }
        }
    }
</script>
