export default function createDataLayer() {
    return store => {

        store.subscribe((action, state) => {

            if(action.type == 'dataLayerAddToCart') {

                dataLayer.push({
                    'event': 'addToCart',
                    'ecommerce': {
                        'currencyCode': 'EUR',
                        'add': {
                            'products': [{
                                'name': action.payload.added.product_name,
                                'id': action.payload.added.entity_id,
                                'price': action.payload.added.price,
                                'quantity': 1,
                                'category' : action.payload.added.cat_name
                            }]
                        }
                    }
                });

                dataLayer.push({
                    'event':'VirtualPageview',
                    'virtualPageURL': window.location.pathname + '/AddToCart',
                    'virtualPageTitle' :  action.payload.added.product_name + ' - AddToCart'
                });

                dataLayer.push({
                    'event':'VirtualPageview',
                    'virtualPageURL': '/samenstellen/AddToCart',
                    'virtualPageTitle' :  'AddToCart'
                });

                var BaseColorID = '';
                var SizeLength = '';
                var SizeHeight = '';

                _.each(action.payload.added.summary, function(option){

                    var kleurExist = new RegExp( '\\b' + 'kleur' + '\\b', 'i').test(option.name);
                    if(kleurExist){
                        BaseColorID = option.value_id;
                    }

                    var breedteExist = new RegExp( '\\b' + 'Breedte' + '\\b', 'i').test(option.name);
                    if(breedteExist){
                        SizeLength = option.value;
                    }

                    var hoogteExist = new RegExp( '\\b' + 'Hoogte' + '\\b', 'i').test(option.name);
                    if(hoogteExist){
                        SizeHeight = option.value;
                    }

                });

                dataLayer.push({
                    "event": 'addToBasket',
                    'Product categorie': action.payload.added.cat_name, //Product categorie
                    'Product Type': action.payload.added.product_type, //Product type
                    'ProductId': action.payload.added.entity_id, //Product id
                    'BaseColorID': BaseColorID, //Basecolorid
                    'Brand': '', //Brand
                    'SizeLength': SizeLength, //Size length
                    'SizeHeight': SizeHeight, //Size height
                    'BasketValue': action.payload.cart.subtotal, //Basketvalue
                    'ProductPrice': action.payload.added.price, //Productprice
                    'OfferteNr': '', //Offertenummer
                    'AccColorID': '', //AccColorid
                });
            }
        })
    }
}