<template>
    <div>
        <div class="content">
            <h3 class="panel-title">{{$t("summary")}}</h3>
            <div class="table-group">
                <div class="row">
                    <div class="col-xs-5">
                        {{$t("reference")}}:
                    </div>
                    <div class="col-xs-7 text-right">
                        <span>
                            {{group}}
                        </span>
                    </div>
                </div>

                <div class="row">
                    <div class="col-xs-6">
                        {{$t("light_colour")}}:
                    </div>
                    <div class="col-xs-6 text-right">
                        <span>
                            {{mainValues.light_colour == 'single_colour' ? 'Single colour' : mainValues.light_colour }}
                        </span>
                    </div>
                </div>

                <div class="row">
                    <div class="col-xs-6">
                        {{$t("voltage")}}:
                    </div>
                    <div class="col-xs-6 text-right">
                        <span>
                            {{mainValues.voltage}}V
                        </span>
                    </div>
                </div>

                <div class="row">
                    <div class="col-xs-6">
                        {{$t("ip_value")}}:
                    </div>
                    <div class="col-xs-6 text-right">
                        <span class="text-uppercase">
                            {{mainValues.ip_value}}
                        </span>
                    </div>
                </div>
            </div>
            <h3 class="panel-title">{{$t("lightsources")}} <span class="pull-right">{{getPowerAmountTotal}}W</span></h3>
            <div class="table-group">
                <div class="lightsource" v-if="lightsources.length > 0"
                     :key="index" v-for="(lightsource, index) in lightsources">
                    <div class="row">
                        <div class="col-xs-8">
                            <label>
                                <span>{{lightsource.reference ? $t('lightsource') + ' ' + lightsource.reference : $t('lightsource_no_reference')}}</span>
                            </label>
                        </div>
                        <div class="col-xs-4 text-right">
                            {{getPowerAmount(lightsource)}}W
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-xs-2 col-sm-2">
                            <img width="100%" :src="lightsource.led_strip.product.image" />
                        </div>
                        <div class="col-xs-7 col-sm-8">
                            {{$t('strip')}} ({{$t('length')}}: {{lightsource.length}}m)<br/>
                            <span>
                                {{lightsource.led_strip.product.product_name}}
                            </span>
                        </div>
                        <div class="col-xs-3 col-sm-2 text-right">
                            {{$t('amount')}} <br/>
                            <input class="form-control text-right" v-model="lightsource.led_strip.amount"/>
                        </div>
                    </div>

                    <hr/>

                    <div class="row">
                        <div class="col-xs-2 col-sm-2">
                            <img width="100%" :src="lightsource.led_profile.product.image" />
                        </div>
                        <div class="col-xs-10 col-sm-10">
                            <div class="row">
                                <div class="col-xs-9 col-sm-9">
                                    {{$t('profile')}}<br/>
                                    <span>
                                        {{lightsource.led_profile.product.product_name}}
                                    </span>
                                    <p>
                                        {{$t('lens')}}:
                                        <span style="display:inline-block;vertical-align:top;">
                                            {{lightsource.led_profile.lens.product_name}}
                                        </span>
                                    </p>
                                </div>
                                <div class="col-xs-3 col-sm-3 text-right">
                                    {{$t('amount')}} <br/>
                                    <input class="form-control text-right" v-model="lightsource.led_profile.amount"/>
                                </div>
                            </div>
                        </div>
                    </div>

                    <hr v-if="lightsource.accessories.length > 0"/>

                    <div class="row"  v-if="lightsource.accessories.length > 0">
                        <div class="col-xs-6">
                            {{$t('accessories')}}
                        </div>
                        <div class="col-xs-6 text-right">
                            {{$t('amount')}}
                        </div>
                    </div>
                    <div  v-if="lightsource.accessories.length > 0" class="row" :key="index" v-for="(accessory, index) in lightsource.accessories">
                        <div class="col-xs-1 col-sm-2">
                            <img width="100%" :src="accessory.image"/>
                        </div>
                        <div class="col-xs-7 col-sm-8">
                            <span>
                                {{accessory.product_name}}
                            </span>
                        </div>
                        <div class="col-xs-3 text-right col-sm-2">
                            <span>
                                <input class="form-control text-right" v-model="accessory.amount"/>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <h3 class="panel-title">{{$t('management')}}</h3>
            <div class="table-group">
                <div class="row">
                    <div class="col-xs-8">
                        {{$t('controller')}}
                    </div>
                    <div class="col-xs-4 text-right">
                        {{$t('amount')}}
                    </div>
                </div>
                <div class="row">
                    <div class="col-xs-2">
                        <img width="100%" :src="controllerSummarized.controller.image" />
                    </div>
                    <div class="col-xs-7 col-sm-8">
                        <span>
                            {{controllerSummarized.controller.product_name}}
                        </span>
                    </div>
                    <div class="col-xs-3 text-right col-sm-2">
                        <input class="form-control text-right" v-model="controllerSummarized.controller.amount"/>
                    </div>
                </div>

                <hr v-if="controllerSummarized.amplifiers.length > 0"/>

                <div class="row" v-if="controllerSummarized.amplifiers.length > 0">
                    <div class="col-xs-8">
                        {{$t('amplifiers')}}
                    </div>
                    <div class="col-xs-4 text-right">
                        {{$t('amount')}}
                    </div>
                </div>
                <div v-if="controllerSummarized.amplifiers.length > 0" class="row" :key="index" v-for="(amplifier, index) in controllerSummarized.amplifiers">
                    <div class="col-xs-2 col-sm-2">
                        <img width="100%" :src="amplifier.image" />
                    </div>
                    <div class="col-xs-7 col-sm-8">
                        <span>
                            {{amplifier.product_name}}
                        </span>
                    </div>
                    <div class="col-xs-3 text-right col-sm-2">
                        <input class="form-control text-right" v-model="amplifier.amount"/>
                    </div>
                </div>

                <hr v-if="controllerSummarized.remotes.length > 0" />

                <div class="row" v-if="controllerSummarized.remotes.length > 0">
                    <div class="col-xs-8">
                        {{$t('remotes')}}
                    </div>
                    <div class="col-xs-4 text-right">
                        {{$t('amount')}}
                    </div>
                </div>
                <div class="row" v-if="controllerSummarized.remotes.length > 0" :key="index" v-for="(remote,index) in controllerSummarized.remotes">
                    <div class="col-xs-2 col-sm-2">
                        <img width="100%" :src="remote.image" />
                    </div>
                    <div class="col-xs-7 col-sm-8">
                        <span>
                            {{remote.product_name}}
                        </span>
                    </div>
                    <div class="col-xs-3 text-right col-sm-2">
                        <input class="form-control text-right" v-model="remote.amount"/>
                    </div>
                </div>
            </div>

            <h3 class="panel-title">{{$t('supply')}}</h3>
            <div class="table-group">
                <div class="row" v-if="supplies.length > 0">
                    <div class="col-xs-8">
                        {{$t('supplies')}}
                    </div>
                    <div class="col-xs-4 text-right">
                        {{$t('amount')}}
                    </div>
                </div>
                <div class="row" v-if="supplies.length > 0" :key="index" v-for="(supply, index) in supplies">
                    <div class="col-xs-2 col-sm-2">
                        <img width="100%" :src="supply.image" />
                    </div>
                    <div class="col-xs-7 col-sm-8">
                        <span>
                            {{supply.product_name}}
                        </span>
                    </div>
                    <div class="col-xs-3 text-right col-sm-2">
                        <input class="form-control text-right" v-model="supply.amount"/>
                    </div>
                </div>
            </div>
        </div>

        <button type="button" class="button black next-step ask-tender" @click="dialogFormVisible = true">
            <i class="fa fa-angle-right"></i>
            <span>{{$t('request_estimate')}}</span>
        </button>

        <el-dialog class="estimate-modal" :title="$t('request_estimate')" :visible.sync="dialogFormVisible" :fullscreen="checkFullscreen">
            <el-form ref="form" :model="form" :rules="rules" label-position="top" v-if="!submitted">
                <div class="row">
                    <div class="col-xs-12">
                        <el-form-item :label="$t('prefix')">
                            <el-radio-group v-model="form.prefix">
                                <el-radio :label="$t('mr')"></el-radio>
                                <el-radio :label="$t('mrs')"></el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </div>
                </div>

                <div class="row">
                    <div class="col-xs-12">
                        <el-form-item label-width="60px" :label="$t('firstname')" prop="firstname">
                            <el-input :placeholder="$t('firstname')" v-model="form.firstname"></el-input>
                        </el-form-item>
                    </div>
                    <div class="col-xs-4">
                        <el-form-item prop="middlename" label="" label-width="1px">
                            <el-input :placeholder="$t('middlename')" v-model="form.middlename"></el-input>
                        </el-form-item>
                    </div>
                    <div class="col-xs-8">
                        <el-form-item prop="lastname" label-width="60px">
                            <el-input :placeholder="$t('lastname')" v-model="form.lastname"></el-input>
                        </el-form-item>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xs-12">
                        <el-form-item label="Bedrijfsnaam" label-width="60px" prop="company">
                            <el-input :placeholder="$t('companyname')" v-model="form.company"></el-input>
                        </el-form-item>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xs-12">
                        <el-form-item label="Email" prop="email">
                            <el-input :placeholder="$t('email')" v-model="form.email"></el-input>
                        </el-form-item>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xs-12">
                        <el-form-item label="Telefoonnummer" prop="phone">
                            <el-input :placeholder="$t('phonenumber')" v-model="form.phone"></el-input>
                        </el-form-item>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xs-6">
                        <el-form-item label="Postcode" prop="postcode">
                            <el-input :placeholder="$t('zipcode')" v-model="form.postcode"></el-input>
                        </el-form-item>
                    </div>
                    <div class="col-xs-6">
                        <el-form-item label="Huisnr." prop="housenumber">
                            <el-input :placeholder="$t('housenumber')" v-model="form.housenumber"></el-input>
                        </el-form-item>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xs-12">
                        <el-form-item label="Notitie">
                            <el-input type="textarea" :placeholder="$t('note')" v-model="form.note"></el-input>
                        </el-form-item>
                    </div>
                </div>
            </el-form>
            <span slot="footer" class="dialog-footer" v-if="!submitted">
                <el-button @click="dialogFormVisible = false">{{$t('cancel')}}</el-button>
                <el-button :loading="loading" type="primary" @click="placeOrder">{{$t('sent')}}</el-button>
            </span>
            <div class="row" v-if="submitted">
                <div class="col-xs-12">
                    {{$t('thanks_for_led_estimate')}}
                </div>
            </div>
            <span slot="footer" class="dialog-footer" v-if="submitted">
                <el-button type="primary" @click="dialogFormVisible = false">{{$t('close')}}</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    import Vue from 'vue'
    import {mapState} from 'vuex'
    import _ from 'lodash'

    export default {
        name: 'LedSummary',
        data () {
            return {
                dialogFormVisible: false,
                form: {
                    prefix: 'Dhr',
                    firstname: '',
                    middlename: '',
                    lastname: '',
                    company: '',
                    email: '',
                    phone: '',
                    postcode: '',
                    housenumber: '',
                    note: ''
                },
                rules: {
                    prefix:  [
                        {required: true, message: this.$t('please_select_a_value'), trigger: 'blur'}
                    ],
                    firstname:  [
                        {required: true, message: this.$t('please_fill_a_firstname'), trigger: 'blur'}
                    ],
                    lastname:  [
                        {required: true, message: this.$t('please_fill_a_lastname'), trigger: 'blur'}
                    ],
                    company:  [
                        {required: true, message: this.$t('please_fill_a_companyname'), trigger: 'blur'}
                    ],
                    email:  [
                        {required: true, message: this.$t('please_fill_an_email'), trigger: 'blur'},
                        { type: 'email', message: this.$t('fill_a valid_email'), trigger: 'blur'}
                    ],
                    phone:  [
                        {required: true, message: this.$t('please_fill_a_phonenumber'), trigger: 'blur'}
                    ],
                    postcode:  [
                        {required: true, message: this.$t('please_fill_a_zipcode'), trigger: 'blur'}
                    ],
                    housenumber:  [
                        {required: true, message: this.$t('please_fill_a_housenumber'), trigger: 'blur'}
                    ]
                },
                loading: false,
                submitted: false
            }
        },
        computed: {
            checkFullscreen(){
                return window.outerWidth <= 767;
            },
            ...mapState('lightsources', {
                lightsources: state => state.lightsources
            }),
            ...mapState('configurator', {
                mainValues: state => state.mainValues,
                group: state => state.group
            }),
            ...mapState('controller', {
                controller: state => state.controller,
                amplifiers: state => state.amplifiers,
                remotes: state => state.remotes
            }),
            ...mapState('supplies', {
                supplies: state => state.supplies
            }),
            controllerSummarized: function () {
                return {
                    controller: {
                        product_id: this.controller.product.product_id,
                        product_name: this.controller.product.product_name ? this.controller.product.product_name : this.$t('no_chosen_controller'),
                        image: this.controller.product.image ? this.controller.product.image : '',
                        amount: this.controller.amount ? this.controller.amount : ''
                    },
                    amplifiers: this.amplifiers,
                    remotes: this.remotes
                }
            },
            getPowerAmountTotal () {
                return _.sumBy(this.lightsources, (lightsource) => {
                    return parseInt(lightsource.led_strip.product.power*lightsource.length)
                })
            }
        },
        methods:{
            getPowerAmount(lightsource) {
                return parseFloat(lightsource.led_strip.product.power*lightsource.length).toFixed(2)
            },
            placeOrder(){
                let self = this
                this.loading = true;
                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        Vue.http.post(
                            '/led/placeEstimate', {
                                form: self.form,
                                configurator: {
                                    reference: self.group,
                                    lightsources: self.lightsources,
                                    controller: self.controllerSummarized,
                                    supplies: self.supplies
                                }
                            }
                        ).then(function(response){
                            if (response.data){
                                self.loading = false
                                self.submitted = true
                            }
                        });
                    } else {
                        self.loading = false
                        return false;
                    }
                });
            }
        }
    }
</script>